import React from 'react';

import { BrowserRouter, Route,Routes as Switch,useLocation, useParams, useNavigate } from 'react-router-dom';

import { getData, getUserID, setData, setCookie, deleteAllCookies } from './getData';

import Header from './Header.js';
import Header2 from './Header2.js';
import Footer from './Footer.js';
import PagePersonalData0 from './Page_Personal_Data5.js';
import PageSecurityLevel0 from './Page_Security_Level.js';
import PageProfile0 from './Page_Profile.js';
import PageOrders0 from './Page_Orders.js';
import PageMap0 from './Page_Map.js';

import PageMain0 from './Page_Main.js';



function PagePersonalData( props ) {
  let params = useParams();
  let location = useLocation();
  let this_ = props.state;
  /*
  <Header
  location={location}
  urlPrefix={props.urlPrefix} buttonState={props.buttonState}
  userInfo={props.userInfo}       
/>
*/
    return (
      <div>
      
      
        <Header2
          location={location}
          urlPrefix={props.urlPrefix} buttonState={props.buttonState}
          userInfo={props.userInfo}       
        />
        <PagePersonalData0
          location={location}
          urlPrefix={props.urlPrefix} buttonState={props.buttonState}
          userInfo={props.userInfo}
          openedWindowRegistration={props.openedWindowRegistration}            
          handleWindowOpenRegistration={props.handleWindowOpenRegistration}

        />

        <Footer location={location} />
      </div>
    ); 
}
function PageProfile( props ) {
  let params = useParams();
  let location = useLocation();
  let this_ = props.state;
  /*
  <Header
  location={location}
  urlPrefix={props.urlPrefix} buttonState={props.buttonState}
  userInfo={props.userInfo}       
/>
*/
    return (
      <div>
      
      
        <Header2
          location={location}
          urlPrefix={props.urlPrefix} buttonState={props.buttonState}
          userInfo={props.userInfo}       
        />
        <PageProfile0
          location={location}
          urlPrefix={props.urlPrefix} buttonState={props.buttonState}
          userInfo={props.userInfo}
          openedWindowRegistration={props.openedWindowRegistration}            
          handleWindowOpenRegistration={props.handleWindowOpenRegistration}

        />

        <Footer location={location} />
      </div>
    ); 
}
function PageMap( props ) {
  let params = useParams();
  let location = useLocation();
  let this_ = props.state;
  /*
  <Header
  location={location}
  urlPrefix={props.urlPrefix} buttonState={props.buttonState}
  userInfo={props.userInfo}       
/>
*/
    return (
      <div>
      
      
        <Header2
          location={location}
          urlPrefix={props.urlPrefix} buttonState={props.buttonState}
          userInfo={props.userInfo}       
        />
        <PageMap0
          location={location}
          urlPrefix={props.urlPrefix} buttonState={props.buttonState}
          userInfo={props.userInfo}
          openedWindowRegistration={props.openedWindowRegistration}            
          handleWindowOpenRegistration={props.handleWindowOpenRegistration}

        />

        <Footer location={location} />
      </div>
    ); 
}
function PageOrders( props ) {
  let params = useParams();
  let location = useLocation();
  let this_ = props.state;
  /*
  <Header
  location={location}
  urlPrefix={props.urlPrefix} buttonState={props.buttonState}
  userInfo={props.userInfo}       
/>
*/
    return (
      <div>
      
      
        <Header2
          location={location}
          urlPrefix={props.urlPrefix} buttonState={props.buttonState}
          userInfo={props.userInfo}       
        />
        <PageOrders0
          location={location}
          urlPrefix={props.urlPrefix} buttonState={props.buttonState}
          userInfo={props.userInfo}
          openedWindowRegistration={props.openedWindowRegistration}            
          handleWindowOpenRegistration={props.handleWindowOpenRegistration}

        />

        <Footer location={location} />
      </div>
    ); 
}

/*
        <button type="button" className="scrollup is-active"><i className="fa fa-arrow-up" aria-hidden="true"></i></button>        
 */

        function PageSecurityLevel( props ) {
          let params = useParams();
          let location = useLocation();
          let this_ = props.state;
          /*
          <Header
          location={location}
          urlPrefix={props.urlPrefix} buttonState={props.buttonState}
          userInfo={props.userInfo}       
        />
        */
            return (
              <div>
              
              
                <Header2
                  location={location}
                  urlPrefix={props.urlPrefix} buttonState={props.buttonState}
                  userInfo={props.userInfo}       
                />
                <PageSecurityLevel0
                  location={location}
                  urlPrefix={props.urlPrefix} buttonState={props.buttonState}
                  userInfo={props.userInfo}
                  openedWindowRegistration={props.openedWindowRegistration}            
                  handleWindowOpenRegistration={props.handleWindowOpenRegistration}
        
                />
        
                <Footer location={location} />
              </div>
            ); 
        }
        /*
                <button type="button" className="scrollup is-active"><i className="fa fa-arrow-up" aria-hidden="true"></i></button>        
         */
        
        
function PageMain( props ) {
  let params = useParams();
  let location = useLocation();
  let this_ = props.state;
    return (
      <div>
      
        <Header2
        location={location}
        urlPrefix={props.urlPrefix} buttonState={props.buttonState}
        userInfo={props.userInfo}              
      />
        <PageMain0
          location={location}
          urlPrefix={props.urlPrefix} buttonState={props.buttonState}
          userInfo={props.userInfo}
          openedWindowRegistration={props.openedWindowRegistration}            
          handleWindowOpenRegistration={props.handleWindowOpenRegistration}

        />

        <Footer location={location} />
      </div>
    ); 
}


class App extends React.Component {
  constructor(props) {
    super(props);
    this.ClientID;
    this.state = {
      //leadColor:"red",
      userInfo:{},

      buttonState:{},
      openedWindowRegistration:0, // Открытое окно регистрации
      openedWindowWriteMessage:0, // Открытое окно написать сообщение
      openedWindowGetRing:0, // Открытое окно заказать звонок
      

      looked: [], // массив просмотренных товаров
      favorite: [], // массив избранных товаров
      order: [], // массив для локальной корзины, к которой обращаются все
      cartId: '',
      //urlPrefix: 'https://dev.ttwcome.ru/api_',  
      urlPrefix: NaN,      
      apiKey: NaN,
      env: NaN,
      //orderId: makeid(),
      //orderDone: null, // Выполненный заказ
    };
    this.onWindowOpenRegistration = this.onWindowOpenRegistration.bind(this);
    this.onWindowCloseRegistration = this.onWindowCloseRegistration.bind(this);
    this.onWindowOpenWriteMessage = this.onWindowOpenWriteMessage.bind(this);
    this.onWindowCloseWriteMessage = this.onWindowCloseWriteMessage.bind(this);
    this.onWindowOpenGetRing = this.onWindowOpenGetRing.bind(this);
    this.onWindowCloseGetRing = this.onWindowCloseGetRing.bind(this);

    this.getUserInfo = this.getUserInfo.bind(this);
    //this.readUserInfo = this.readUserInfo.bind(this);
    this.readUserInfo = this.readUserInfo.bind(this);// Перечитываем глобально из любого места

    this.getRingState=this.getRingState.bind(this);
    this.getWriteMessageState=this.getWriteMessageState.bind(this);    
    this.getRegistrationState=this.getRegistrationState.bind(this);    
    //this.changeLeadColor=this.changeLeadColor.bind(this);        
  }
  /*
  changeLeadColor(leadColor)
  {
    this.setState({ leadColor: leadColor}); 
  }
  */
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Регистрация
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  getRegistrationState(data)
  {
    //console.log(data);
    
    if ((data.status!==undefined)&&(data.status=="success")&&(data.data.state!==undefined))
    {
      // 1+0=1 - Не было такого, нужно рисовать полную форму!
      // 1+1=2 - Был такой, уже знаем, вообще можно сразу написать что-нибудь, типа что перезвоним!
      //this.setState({ openedWindowGetRing: data.data.state+1}); 
      this.setState({ openedWindowRegistration: data.data.state+1}); 
    };
    
  }

  // Раньше было - мы просто открывали окно. 
  // А теперь надо запрос сперва сделать, а потом открывать окно!
  onWindowOpenRegistration(data){
    console.log(data); // Узнаем какую именно кнопку нажали
    
    //this.setState({ openedWindowRegistration: true}); 
    
    this.setState({ buttonState: data}); // Но вообще можно точнее все указать и дописать. Какая страница, например.
    let way=this.state.urlPrefix;
    //getData(way+"/client/"+this.ClientID+"?id="+data.id+"&end",this.getRegistrationState);
    
    

  }
  onWindowCloseRegistration(){
    this.setState({ openedWindowRegistration: 0}); 
  }
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Сообщение пользователю
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  getWriteMessageState(data)
  {
    //console.log(data);
    
    if ((data.status!==undefined)&&(data.status=="success")&&(data.data.state!==undefined))
    {
      // 1+0=1 - Не было такого, нужно рисовать полную форму!
      // 1+1=2 - Был такой, уже знаем, вообще можно сразу написать что-нибудь, типа что перезвоним!
      //this.setState({ openedWindowGetRing: data.data.state+1}); 
      this.setState({ openedWindowWriteMessage: data.data.state+1}); 
    };
    
  }

  // Раньше было - мы просто открывали окно. 
  // А теперь надо запрос сперва сделать, а потом открывать окно!
  onWindowOpenWriteMessage(){
    //this.setState({ openedWindowWriteMessage: true}); 
    let way=this.state.urlPrefix;
    //getData(way+"/client/"+this.ClientID+"?id=write_to_manager&end",this.getWriteMessageState);

  }
  onWindowCloseWriteMessage(){
    this.setState({ openedWindowWriteMessage: 0}); 
  }
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Обратный звонок
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  getRingState(data)
  {
    if ((data.status!==undefined)&&(data.status=="success")&&(data.data.state!==undefined))
    {
      // 1+0=1 - Не было такого, нужно рисовать полную форму!
      // 1+1=2 - Был такой, уже знаем, вообще можно сразу написать что-нибудь, типа что перезвоним!
      this.setState({ openedWindowGetRing: data.data.state+1}); 
    };
  }
  // Раньше было - мы просто открывали окно. 
  // А теперь надо запрос сперва сделать, а потом открывать или нет окно!
  onWindowOpenGetRing(){
    //this.setState({ openedWindowGetRing: true}); 
    let way=this.state.urlPrefix;
    //getData(way+"/client/"+this.ClientID+"?id=call_back",this.getRingState);
  }
  onWindowCloseGetRing(){
    this.setState({ openedWindowGetRing: 0}); 
  }

  // Получаем версию
  getVersion(data) {
    console.log(data.data);
    //console.log(process.env);
  }
  /*
  readUserInfo(userInfo){
    this.setState({ userInfo: userInfo}); 
  } 
  */ 
  // Получили имя, установили куки
  getUserInfo(data) {
    //console.log(data); 
    if ((data.status) && (data.status=="success")&& (data.data.state)&& (data.data.state=="1"))
    {
      let userInfo={};
      userInfo.name=data.data.infoToClient;
      userInfo.packet_type_1=data.data.order.packet_type_1;
      //this.readUserInfo(userInfo);
      this.setState({ userInfo: userInfo}); 
    }
  }

  // Перечитываем данные о пользователе из любого места
  readUserInfo(way="")
  {
    if (way=="")
      way=this.state.urlPrefix;
    console.log(this.ClientID);
    getData(way+"/client/"+this.ClientID+"/",this.getUserInfo);
  }
  componentDidMount() { 
    let way=process.env.REACT_APP_API_WAY;
    //getData(way+"/version",this.getVersion);     

    this.setState({
      urlPrefix:way,
      apiKey: process.env.REACT_APP_OPEN_KEY,
      env:process.env.NODE_ENV
    });
    if (!this.ClientID) this.ClientID=getUserID(); 
    this.readUserInfo(way);

  }
/*
  <DataBase
  global
  setGlobalOrder={this.setOrderFromBase.bind(this)}
  setAddOrder={this.setOrder.bind(this)}
  cartId={this.state.cartId}
  urlPrefix={this.state.urlPrefix} buttonState={this.state.buttonState}
  orderId={this.state.orderId}
  
  setCartId={this.setCartId.bind(this)}
/>
*/
  
  render() {
    
    if (!this.state.apiKey) return(<div/>); 
    //console.log(this.state.userInfo); 
    //console.log(this.state);
    return (
      <BrowserRouter>
        <div>

        

          <Switch>
          <Route path="/map" exact element={<PageMap
              urlPrefix={this.state.urlPrefix} buttonState={this.state.buttonState}
              userInfo={this.state.userInfo}
              handleReadUserInfo={this.readUserInfo}

              openedWindowRegistration={this.state.openedWindowRegistration}
              handleWindowOpenRegistration={this.onWindowOpenRegistration}
              handleWindowCloseRegistration={this.onWindowCloseRegistration}    

              openedWindowGetRing={this.state.openedWindowGetRing}
              handleWindowOpenGetRing={this.onWindowOpenGetRing}
              handleWindowCloseGetRing={this.onWindowCloseGetRing}    
              
              openedWindowWriteMessage={this.state.openedWindowWriteMessage}
              handleWindowOpenWriteMessage={this.onWindowOpenWriteMessage}
              handleWindowCloseWriteMessage={this.onWindowCloseWriteMessage}    



            />}/>
          <Route path="/profile" exact element={<PageProfile
              urlPrefix={this.state.urlPrefix} buttonState={this.state.buttonState}
              userInfo={this.state.userInfo}
              handleReadUserInfo={this.readUserInfo}

              openedWindowRegistration={this.state.openedWindowRegistration}
              handleWindowOpenRegistration={this.onWindowOpenRegistration}
              handleWindowCloseRegistration={this.onWindowCloseRegistration}    

              openedWindowGetRing={this.state.openedWindowGetRing}
              handleWindowOpenGetRing={this.onWindowOpenGetRing}
              handleWindowCloseGetRing={this.onWindowCloseGetRing}    
              
              openedWindowWriteMessage={this.state.openedWindowWriteMessage}
              handleWindowOpenWriteMessage={this.onWindowOpenWriteMessage}
              handleWindowCloseWriteMessage={this.onWindowCloseWriteMessage}    



            />}/>
          <Route path="/orders" exact element={<PageOrders
              urlPrefix={this.state.urlPrefix} buttonState={this.state.buttonState}
              userInfo={this.state.userInfo}
              handleReadUserInfo={this.readUserInfo}

              openedWindowRegistration={this.state.openedWindowRegistration}
              handleWindowOpenRegistration={this.onWindowOpenRegistration}
              handleWindowCloseRegistration={this.onWindowCloseRegistration}    

              openedWindowGetRing={this.state.openedWindowGetRing}
              handleWindowOpenGetRing={this.onWindowOpenGetRing}
              handleWindowCloseGetRing={this.onWindowCloseGetRing}    
              
              openedWindowWriteMessage={this.state.openedWindowWriteMessage}
              handleWindowOpenWriteMessage={this.onWindowOpenWriteMessage}
              handleWindowCloseWriteMessage={this.onWindowCloseWriteMessage}    



            />}/>

          <Route path="/personal-data/security-level" exact element={<PageSecurityLevel
              urlPrefix={this.state.urlPrefix} buttonState={this.state.buttonState}
              userInfo={this.state.userInfo}
              handleReadUserInfo={this.readUserInfo}

              openedWindowRegistration={this.state.openedWindowRegistration}
              handleWindowOpenRegistration={this.onWindowOpenRegistration}
              handleWindowCloseRegistration={this.onWindowCloseRegistration}    

              openedWindowGetRing={this.state.openedWindowGetRing}
              handleWindowOpenGetRing={this.onWindowOpenGetRing}
              handleWindowCloseGetRing={this.onWindowCloseGetRing}    
              
              openedWindowWriteMessage={this.state.openedWindowWriteMessage}
              handleWindowOpenWriteMessage={this.onWindowOpenWriteMessage}
              handleWindowCloseWriteMessage={this.onWindowCloseWriteMessage}    



            />}/>
          <Route path="/personal-data" exact element={<PagePersonalData
              urlPrefix={this.state.urlPrefix} buttonState={this.state.buttonState}
              userInfo={this.state.userInfo}
              handleReadUserInfo={this.readUserInfo}

              openedWindowRegistration={this.state.openedWindowRegistration}
              handleWindowOpenRegistration={this.onWindowOpenRegistration}
              handleWindowCloseRegistration={this.onWindowCloseRegistration}    

              openedWindowGetRing={this.state.openedWindowGetRing}
              handleWindowOpenGetRing={this.onWindowOpenGetRing}
              handleWindowCloseGetRing={this.onWindowCloseGetRing}    
              
              openedWindowWriteMessage={this.state.openedWindowWriteMessage}
              handleWindowOpenWriteMessage={this.onWindowOpenWriteMessage}
              handleWindowCloseWriteMessage={this.onWindowCloseWriteMessage}    



            />}/>
             <Route path="/*" exact element={<PageMain
              urlPrefix={this.state.urlPrefix} buttonState={this.state.buttonState}
              userInfo={this.state.userInfo}
              handleReadUserInfo={this.readUserInfo}

              openedWindowRegistration={this.state.openedWindowRegistration}
              handleWindowOpenRegistration={this.onWindowOpenRegistration}
              handleWindowCloseRegistration={this.onWindowCloseRegistration}    

              openedWindowGetRing={this.state.openedWindowGetRing}
              handleWindowOpenGetRing={this.onWindowOpenGetRing}
              handleWindowCloseGetRing={this.onWindowCloseGetRing}    
              
              openedWindowWriteMessage={this.state.openedWindowWriteMessage}
              handleWindowOpenWriteMessage={this.onWindowOpenWriteMessage}
              handleWindowCloseWriteMessage={this.onWindowCloseWriteMessage}    



            />}/>



            
          </Switch>
        </div>
      </BrowserRouter >
    );
  }
}

/*
            <Route path="/ektp" exact element={<PageEKTP
              urlPrefix={this.state.urlPrefix} buttonState={this.state.buttonState}
              
              
              
              
              
              
            />}/>

            />
             <Route path="/ektp" exact element={<EKTPPage
              urlPrefix={this.state.urlPrefix} buttonState={this.state.buttonState}
              
              
              
              
              
              
            />}
             */
export default App;
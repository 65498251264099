import React from 'react';
import { Tooltip, Spin, Drawer,Button,Modal, Form, Input, Select } from 'antd';
import { getData,postData,getUserID,downloadFile } from './getData';
import {ConstPersonalData} from './Const_Personal_Data.js';
import {  CheckCircleOutlined  }  from '@ant-design/icons';

const ShowModal = (props) => {
   //console.log(props);
   const { Option } = Select;
   const layout = {
     labelCol: {
       span: 8,
     },
     wrapperCol: {
       span: 16,
     },
   };
   const tailLayout = {
     wrapperCol: {
       offset: 8,
       span: 16,
     },
   };   
   const [form] = Form.useForm();
   props.handleForm(form);
   const onFinish = (values) => {
     //console.log(values);
     props.handleOk(values);
   };
   const onChange = (e) => {
      const target = e.target;
      const value = target.type === 'text' ?  target.value:'';
      let inn = value.match(/[\d]+/i);    
      let inn_=(inn)?inn[0]:'';
      inn=inn_;   
      //console.log(inn);
      if ((inn.length==10)||(inn.length==12))
      {
         props.handleCheckINN(inn);
        //getData(this.props.urlPrefix+"/client/"+this.ClientID+"?inn="+inn+"&end",this.getINNCheck);
      }      
      //props.handleOk(values);
    };
 
   /*
   const onReset = () => {
     form.resetFields();
   };
 
   const onFill = () => {
     form.setFieldsValue({
       note: 'Hello world!',
       gender: 'male',
     });
   };
   */   
   //{props.handleOk}
   return(
<Modal
      title={props.title}
      visible={props.visible}
      //onOk={onFinish}
      confirmLoading={props.confirmLoading}
      onCancel={props.handleCancel}
      footer={[]}
      >      
    <p>{props.modalText}</p>    
      <Form //{...layout} 
      layout="vertical"

      form={form} name="control-hooks" onFinish={onFinish} >
      <Form.Item
        name="inn"
        label="Ваш ИНН:"

        rules={[
          {
            required: true, message: 'Заполните поле ИНН' 
          },
        ]}
      >
        <Input 
         placeholder="1234567890"
         onChange={onChange}
        />
      </Form.Item>
      <Form.Item
        name="company_name_short"
        label="Название вашей компании (подставляется автоматически по ИНН):"
        

        rules={[
          {
            required: true, message: '' 
          },
        ]}
      >
        <Input disabled={true} placeholder="1234567890"/>
      </Form.Item>

      <Form.Item
        name="is_name"
        label="Название вашей информационной системы:"
        defaultValue="1"
        rules={[
          {
            required: true, message: 'Заполните название информационной системы' 
          },
        ]}
      >
        <Input placeholder="1С:Бухгалтерия"/>
      </Form.Item>
      <Form.Item
      // {...tailLayout}
      >
        <Button type="primary" htmlType="submit">
          Подтвердить
        </Button>
      </Form.Item>
    </Form>  
</Modal>
);
}

const SecurityChoiceLevel = (props) => {
  //console.log(ConstPersonalData[security_choice_level1]);
  return (ConstPersonalData[props.security_choice_level].value );
  };
 
class Module_FastDoc extends React.Component {
  constructor(props) {
    super(props);
    this.ClientID;
    this.state = {
      /*
      question:[],
      answer:0,
      loading:true,
      */
      visible:false,
      confirmLoading:false,
      modalText:'Введите данные для формирования документов:',
      
   }
    this.handleFormVar=null;

    //this.onChange = this.onChange.bind(this);
    this.onClick = this.onClick.bind(this);
    this.getFileDownload = this.getFileDownload.bind(this);    
    //this.getResultSecLevel = this.getResultSecLevel.bind(this);  

    this.setVisible = this.setVisible.bind(this);
    this.setConfirmLoading = this.setConfirmLoading.bind(this);
    this.setModalText = this.setModalText.bind(this);
    this.showModal = this.showModal.bind(this);
    this.handleOk = this.handleOk.bind(this);
    this.handleCancel = this.handleCancel.bind(this);

    //this.getResultSecLevel = this.getResultSecLevel.bind(this);
    this.handleForm = this.handleForm.bind(this);
    this.getResultShowModal = this.getResultShowModal.bind(this);

    this.handleCheckINN = this.handleCheckINN.bind(this);
    this.getResultINN = this.getResultINN.bind(this);
    this.afterPost = this.afterPost.bind(this);

  }
  getResultINN(data)
  {
   if (data.status=='error')
   {
      console.log('Ошибка ИНН');
   }
   else
   {
      //console.log(data);
      this.handleFormVar.setFieldsValue({
         inn: data.data.inn,
         company_name_short: data.data.name,
       });
   
   };
  };
  handleCheckINN(inn)
  {
   
   getData(this.props.urlPrefix+"/client/?inn="+inn,this.getResultINN);
   //this.setState({ visible:visible });
  }
  setVisible(visible)
  {
   this.setState({ visible:visible });
  }
  setConfirmLoading(confirmLoading)
  {
   this.setState({ confirmLoading:confirmLoading });
  }
  setModalText(modalText)
  {
   this.setState({ modalText:modalText });
  }
  getResultShowModal(data)
  {
     //console.log(data);
     //console.log(this);
     
     this.handleFormVar.setFieldsValue({
      inn: data.data.company_inn,
      is_name: data.data.is_name,
      company_name_short:data.data.company_name_short,
    });
    

  }
  showModal () {
   this.setVisible(true);
   getData(this.props.urlPrefix+"/fastdoc/"+this.ClientID,this.getResultShowModal);
   /*
   form.setFieldsValue({
      note: 'Hello world!',
      gender: 'male',
    });
    */

  };
  handleForm (data) {
   //this.setState({ handleForm:data });
     //console.log(data);
     this.handleFormVar=data;
  }

  afterPost (data) {
   //this.setState({ handleForm:data });
     //console.log(data);
     this.setVisible(false);
     this.setConfirmLoading(false);
     this.onClick(null);

     //this.handleFormVar=data;
  }

  handleOk (data) {
     //console.log(data);
     // 
   this.setModalText('Загружаю данные...');
   //getData(this.props.urlPrefix+"/seclevel/"+this.ClientID,this.getResultSecLevel);
   this.setConfirmLoading(true);

   var sum_data={};
   sum_data['ClientID']=this.ClientID;
   sum_data['inn']=data.inn;
   sum_data['is_name']=data.is_name;
   postData(this.props.urlPrefix+"/fastdoc/",this.ClientID,this.afterPost,sum_data);
   /*
    setTimeout(() => {
      this.setVisible(false);
      this.setConfirmLoading(false);
      this.onClick(null);
    }, 2000);
   */    
  };

  handleCancel () {
    //console.log('Clicked cancel button');
    this.setVisible(false);
  };

  componentDidMount() {
    if (!this.ClientID) this.ClientID=getUserID(); 

    /*
   let question=[];
   question[1]=0;
   question[2]=0;
   question[3]=0;
   question[4]=0;

   this.setState({ question: question });
   getData(this.props.urlPrefix+"/seclevel/"+this.ClientID,this.getResultSecLevel);
   this.setState({ loading:true });
   */
  }
  getFileDownload(data)
  {
    console.log(data);
    
    
    if (data.status=="success")
    {
      //window.open(this.props.urlPrefix+"/makedoc/act1?file_name="+data.data.file_name, '_blank');
      //downloadFile(data.data.url, "Акт определения уровня защищенности.zip");
      downloadFile(data.data.url, "Пакет локальных нормативных актов по персональным данным.zip");
    } else
    {
      
    }
    

  }
  onClick(e) {
    
    /*
    var sum_data={};
    sum_data['ClientID']=this.ClientID;
    //postData(this.props.urlPrefix+"/makedoc/act1/",this.ClientID,this.getFileDownload,sum_data);
    postData(this.props.urlPrefix+"/makedoc/is1/",this.ClientID,this.getFileDownload,sum_data);
    */

    this.props.onClick();
    /*
    if (!this.ClientID) this.ClientID=getUserID(); 
   let question=[];
   question[1]=0;
   question[2]=0;
   question[3]=0;
   question[4]=0;

   this.setState({ question: question });
   getData(this.props.urlPrefix+"/seclevel/"+this.ClientID,this.getResultSecLevel);
   this.setState({ loading:true });
   */
  }
  
  render() {
   if ((!this.props.buttons)||(!this.props.buttons.word)||(this.props.buttons.word.visible==false))
     return(<div className="button_invisible"/>);
   var size="default";
   return (
    <div className="button_invisible">

    <Tooltip title={"Скачать '"+this.props.name+"' в формате Word"}>
      <Button disabled={this.props.loading} className="button_green" icon={<CheckCircleOutlined />} type="primary" shape="round" size={size} onClick={this.showModal} >
        Скачать Word
      </Button>
    </Tooltip>

    <ShowModal
          title="Подготовка данных"
          visible={this.state.visible}
          handleOk={this.handleOk}
          handleForm={this.handleForm}
          confirmLoading={this.state.confirmLoading}
          handleCancel={this.handleCancel}
          modalText={this.state.modalText}
          handleCheckINN={this.handleCheckINN}
          />

    </div>
    );
  }
};

export default Module_FastDoc;
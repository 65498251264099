function deleteAllCookies() {
  if (document.cookie !== '') {
    const c = document.cookie.split('; ');
    let i = 0;
    for (i in c) document.cookie = `${/^[^=]+/.exec(c[i])[0]}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  }
}

// возвращает cookie с именем name, если есть, если нет, то undefined
function getCookie(name) {
  const matches = document.cookie.match(new RegExp(
    // eslint-disable-next-line
    `(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`,
  ));
  // console.log(document.cookie);
  return matches ? decodeURIComponent(matches[1]) : undefined;
  // return document.cookie;
}

/////////////////////////////////////////////////////////////
// возвращает идентификатор пользователя в бразуере без Ya
function uuidv4() {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}

function getUserID() {
  let myStorage = window.localStorage;
  let UserID = myStorage.getItem('TempID');
  //console.log( UserID);
  if (!UserID)
  {
    UserID=uuidv4();
    myStorage.setItem('TempID', UserID);
  }
  return UserID;
  // return document.cookie;
}

function setCookie(name, value) {
// console.log(name);
  // deleteCookie(name);
  let options = {};
  options.path = '/';

  options = options || {};

  let expires = options.expires;

  if (typeof expires === 'number' && expires) {
    const d = new Date();
    d.setTime(d.getTime() + expires * 1000);
    expires = options.expires = d;
  }
  if (expires && expires.toUTCString) {
    options.expires = expires.toUTCString();
  }

  value = encodeURIComponent(value);

  let updatedCookie = `${name}=${value}`;

  for (const propName in options) {
    updatedCookie += `; ${propName}`;
    const propValue = options[propName];
    if (propValue !== true) {
      updatedCookie += `=${propValue}`;
    }
  }

  document.cookie = updatedCookie;
  // console.log(updatedCookie);
}

function deleteCookie(name) {
  setCookie(name, '', {
    expires: -1,
  });
}

function getData(str, setData) {
  const params = {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      
      //'token': 'kDiQZ3scbnY11BALOM0NWOKP6JhzQP',
      'Authorization':'Bearer '+process.env.REACT_APP_OPEN_KEY,
      //"User-Agent"   : "MY-UA-STRING"
      //body: new URLSearchParams(dataForSend),

    }),
  };
  //console.log('GetData: '+str);
  fetch(str, params)
    .then(response => response.json())
    .then((data) => {
      if (data.status && (data.status === 'success')) {
        setData(data);
       //console.log(data);
      } else {
        console.log(`Данные ${str} получены, но с ошибкой`, data);
        setData(data);
        /*
        if (str.indexOf('/cart/') + 1) {
          deleteAllCookies();
          console.log(getCookie('cartId'));
        }
        */
      }
    }).catch((error) => {
      console.log(`Ошибка запроса к строке ${str}`, error);
    });
}

function setData(str, cartId, setDataFunction, dataForSend) {
  const params = {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify(dataForSend),
  };
  // console.log('GetDataAfterSave: '+str+cartId);
  // console.log(params);
  fetch(str + cartId, params)
    .then(response => response.json())
    .then((data) => {
      if (data.status && (data.status === 'ok')) {
        setDataFunction(data);
      // console.log(data);
      } else {
        console.log(`Данные ${str}${cartId} получены, но с ошибкой`, data);
        //setDataFunction(data);
        // Если нет такой корзины, новую делаем!
        /*
        if (cartId !== '') {
        // deleteCookie('cartId');
          deleteAllCookies();
          setData(str, '', setDataFunction, dataForSend);
        }
        */
        
      }
    }).catch((error) => {
      console.log(`Ошибка запроса к строке ${str}`, error);
    });
}
function postData(str, cartId, setDataFunction, dataForSend) {
  const params = {
    method: 'POST',
    headers:  new Headers({
      //'Content-Type': 'application/json',
      //'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization':'Bearer '+process.env.REACT_APP_OPEN_KEY,
    }),
    //body: JSON.stringify(dataForSend),
    body: new URLSearchParams(dataForSend),
    //content: dataForSend,
  };
  // console.log('GetDataAfterSave: '+str+cartId);
  // console.log(params);
  fetch(str + cartId, params)
    .then(response => response.json())
    .then((data) => {
      //console.log(data);
      if (data.status && (data.status === 'success')) {
        setDataFunction(data);
      // console.log(data);
      } else {
        console.log(`Данные ${str}${cartId} получены, но с ошибкой`, data);
        // Если нет такой корзины, новую делаем!
        // Нет, совсем другая тема!
        /*
        if (cartId !== '') {
        // deleteCookie('cartId');
          deleteAllCookies();
          postData(str, '', setDataFunction, dataForSend);
        }
        */
      }
    }).catch((error) => {
      console.log(`Ошибка запроса к строке ${str}`, error);
    });
}
function downloadFile(link,file_name,setStatePDFLoadingFalse=undefined,setStatePDFLoadingTrue=undefined){
  //let link=data.data.url; 
  if (setStatePDFLoadingTrue)
    setStatePDFLoadingTrue();
  var xhr = new XMLHttpRequest();
  xhr.open('GET', link, true);
  xhr.responseType = 'blob';
  xhr.onerror = function(err){
      // Здесь можно выполнять альтернативный вариант
  }
  xhr.onloadstart = function(){}
  xhr.onprogress = function(xhrProgressEvent){
      // Здесь можно обновлять статус загрузки (progressbar)
      // xhrProgressEvent.total -  размер загружаемого файла в байтах
      // xhrProgressEvent.loaded - размер уже загруженного фрагмента в байтах
  }
  xhr.ontimeout = function(){}
  xhr.onloadend = function(){}
  xhr.onload = function () {
      if (this.status == 200) {
          var blob = this.response,
              a = document.createElement('a');
          a.download = file_name;
          a.style = "display: none;";
          a.onload = function () {
              window.URL.revokeObjectURL(a.href);
          };
          a.href = window.URL.createObjectURL(blob);
          document.body.appendChild(a);
          a.click();
          if (setStatePDFLoadingFalse)
            setStatePDFLoadingFalse();
      }
  }
  xhr.send()      

}
export {
  getData, setData, setCookie, getCookie, deleteCookie, deleteAllCookies,postData,getUserID,downloadFile
};

/*
    let demoURL=this.props.urlPrefix+"/Docs/demoPDF.zip";
    this.setState({ loading:true });
    
    fetch(demoURL)
    .then(resp => resp.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      // the filename you want
      a.download = "Демо.zip";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      this.setState({ loading:false });
      //alert('your file has downloaded!'); // or you know, something with better UX...
    })
    .catch(() => {
      alert('Все сломалось... Файл не загрузился...')
      this.setState({ loading:false });
    });    

    */
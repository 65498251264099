import React from 'react';
import { Switch, Spin, Drawer,Button,Modal, Form, Input, Select } from 'antd';
import { isStateMemberExpression } from 'eslint-plugin-react/lib/util/componentUtil';
import { getData,postData,getUserID,downloadFile } from './getData';
import {ConstPersonalData} from './Const_Personal_Data.js';
import operator1 from '../imgs/operator1.png';
import Header_Menu from './Header_Menu.js';
import {  CheckCircleOutlined,IssuesCloseOutlined  }  from '@ant-design/icons';
import Module_FilesDownload from './Module_FilesDownload.js';


class Main extends React.Component {
  constructor(props) {
    super(props);
    this.ClientID;
    this.state = {

      
   }

   this.onClick = this.onClick.bind(this);
   this.getFileDownload = this.getFileDownload.bind(this);    
   this.onClickUved = this.onClickUved.bind(this);
   this.getFileDownload1 = this.getFileDownload1.bind(this);    

  }

  componentDidMount() {
    if (!this.ClientID) this.ClientID=getUserID(); 
  }
  getFileDownload(data)
  {
    console.log(data);
    
    
    if (data.status=="success")
    {
      //window.open(this.props.urlPrefix+"/makedoc/act1?file_name="+data.data.file_name, '_blank');
      downloadFile(data.data.url, "Акт определения уровня защищенности.zip");
    } else
    {
      
    }
    

  }
  /*
  onClick(e) {
    
    
    var sum_data={};
    sum_data['ClientID']=this.ClientID;
    postData(this.props.urlPrefix+"/makedoc/act1/",this.ClientID,this.getFileDownload,sum_data);

  }
  */
  getFileDownload(data)
  {
    //console.log(data);
    if (data.status=="success")
    {
      //window.open(this.props.urlPrefix+"/makedoc/act1?file_name="+data.data.file_name, '_blank');     
      downloadFile(data.data.url, "Пакет локальных нормативных актов по персональным данным.zip");
    } else
    {
      
    }
}
getFileDownload1(data)
{
  if (data.status=="success")
  {
    downloadFile(data.data.url, "Уведомление в Роскомнадзор.zip");
  }
}
onClick(e) {
   var sum_data={};
   sum_data['ClientID']=this.ClientID;
   //sum_data['IS_ID']='ddbb283d-683c-4e17-8796-8867cf09bb74';
   postData(this.props.urlPrefix+"/makedoc/is1/",this.ClientID,this.getFileDownload,sum_data);
 }
 onClickUved(e) {
   var sum_data={};
   sum_data['ClientID']=this.ClientID;
   //sum_data['IS_ID']='ddbb283d-683c-4e17-8796-8867cf09bb74';
   postData(this.props.urlPrefix+"/makedoc/uved1/",this.ClientID,this.getFileDownload1,sum_data);
 }
  
 
  render() {
   //console.log(this.state);
   var size="default";
    return (
<div>
<section id="breadcrumb-section" className="breadcrumb-area breadcrumb-left действующий эффект хлебных крошек">
	<div className="av-container">
		<div className="av-columns-area">
			<div className="av-column-12">
				<div className="breadcrumb-content">
					<div className="breadcrumb-heading wow fadeInLeft" style={{"visibility": "visible", "animationName": "fadeInLeft"}}>
						<h1>Персональные данные</h1>	
					</div>
               <div className="breadcrumb-list_" >
                  <Header_Menu/>
                  <ol className="breadcrumb-list wow fadeInRight" style={{"visibility": "visible", "animationName": "fadeInRight"}}>
                     <li>
                        <a href="/">Информационная безопасность</a> 
                     </li>					
                  </ol>
               </div>
				</div>
			</div>
		</div>
	</div>
</section>


<div id="content" className="gradiant-content">
   <section id="post-section" className="post-section av-py-default">
      <div className="av-container">
         <div className="av-columns-area">
            <div id="av-primary-content" className="av-column-12">
               <div data-elementor-type="wp-page" data-elementor-id="108" className="elementor elementor-108">
                      

                  <section className="elementor-section elementor-top-section elementor-element elementor-element-adabee6 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="adabee6" data-element_type="section">
                     <div className="elementor-container elementor-column-gap-default">
                        <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b034990" data-id="b034990" data-element_type="column">
                           <div className="elementor-widget-wrap elementor-element-populated">
                              <div className="elementor-element elementor-element-8291bb3 elementor-widget elementor-widget-heading" data-id="8291bb3" data-element_type="widget" data-widget_type="heading.default">
                                                               
                                 <div className="elementor-widget-container">
                                    <h2 className="elementor-heading-title elementor-size-default">Что такое персональные данные?</h2>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </section>
                  
                  <section className="elementor-section elementor-top-section elementor-element elementor-element-d4987c6 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="d4987c6" data-element_type="section">
                     <div className="elementor-container elementor-column-gap-default">
                        <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-d28f52c" data-id="d28f52c" data-element_type="column">
                           <div className="elementor-widget-wrap elementor-element-populated">
                              <div className="elementor-element elementor-element-6f16a2d elementor-widget elementor-widget-text-editor" data-id="6f16a2d" data-element_type="widget" data-widget_type="text-editor.default">
                                 <div className="elementor-widget-container">
                                    <p><em>Персональные данные - это <b>любая информация</b>, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных).</em></p>
                                    <p>А телефон это персональные данные? А электронная почта это персональные данные? А фамилия без имени это персональные данные? А имя без фамилии?</p>
                                    <p>Если по вашим данным можно определить человека - то это персональные данные.</p>
                                    <ul>
                                          <li>"Андройдов Диод Аксонович" - не ПДн, это просто ФИО;</li>
                                          <li>"г. Цифрьск, ул. 2-ой Аналоговый тупик, д. 0, кв. 0" - не ПДн, это просто адрес;</li>
                                          <li>"Андройдов Диод Аксонович, адрес: г. Цифрьск, ул. 2-ой Аналоговый тупик, д. 0, кв. 0" - это ПДн, по ним можно определить физическое лицо.</li>
                                    </ul>
                                    <p>Есть ряд данных, которые в совокупности с ФИО превращают данные в ПДн: </p>
                                    <ul>
                                       <li>номер паспорта;</li>
                                       <li>СНИЛС, ИНН физического лица;</li>
                                       <li>номер водительского удостоверения;</li>
                                       <li>номер банковской карты, банковского счета;</li>
                                       <li>домашний адрес;</li>
                                       <li>номер сотового телефона;</li>
                                       <li>личный email и т.д.</li>
                                    </ul>
                                    
                                    <p>Если к уже имеющимся персональным данным добавить некоторые дополнительные данные, то состав персональных данных будет расширен. Например:</p>
                                    <ul>
                                       <li>пол;</li>
                                       <li>возраст, дата рождения;</li>
                                       <li>раса;</li>
                                       <li>данные о состоянии здоровья;</li>
                                       <li>отпечатки пальцев;</li>
                                       <li>веб-файл cookie о посещении конкретных страниц и т.д.</li>
                                    </ul>
                                    
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </section>
                  

                  <section className="elementor-section elementor-top-section elementor-element elementor-element-adabee6 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="adabee6" data-element_type="section">
                     <div className="elementor-container elementor-column-gap-default">
                        <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b034990" data-id="b034990" data-element_type="column">
                           <div className="elementor-widget-wrap elementor-element-populated">
                              <div className="elementor-element elementor-element-8291bb3 elementor-widget elementor-widget-heading" data-id="8291bb3" data-element_type="widget" data-widget_type="heading.default">
                                 <div className="elementor-widget-container">
                                    <h2 className="elementor-heading-title elementor-size-default">Обработка персональных данных</h2>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </section>
                  
                  <section className="elementor-section elementor-top-section elementor-element elementor-element-d4987c6 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="d4987c6" data-element_type="section">
                     <div className="elementor-container elementor-column-gap-default">
                        <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-d28f52c" data-id="d28f52c" data-element_type="column">
                           <div className="elementor-widget-wrap elementor-element-populated">
                              <div className="elementor-element elementor-element-6f16a2d elementor-widget elementor-widget-text-editor" data-id="6f16a2d" data-element_type="widget" data-widget_type="text-editor.default">
                                 <div className="elementor-widget-container">
                                    <p><em>Обработка персональных данных – любое действие с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных</em></p>
                                    <p>Если вы просто открыли страницу с персональными данными в браузере или на компьютере, чтобы посмотреть, то это тоже обработка персональных данных, в данном случае - использование.</p>
                                    <p>Обработкой персональных данных занимается либо Оператор персональных данных, либо иное лицо, которому Оператор персональных данных поручил обработку ПДн с согласия Субъекта ПДн.</p>
                                    <p>Для обработки персональных данных должен быть оформлен ряд документов.</p>
                                    <img src={operator1} alt="Документы: согласие, поручение"></img>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </section>

                  <section className="elementor-section elementor-top-section elementor-element elementor-element-adabee6 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="adabee6" data-element_type="section">
                     <div className="elementor-container elementor-column-gap-default">
                        <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b034990" data-id="b034990" data-element_type="column">
                           <div className="elementor-widget-wrap elementor-element-populated">
                              <div className="elementor-element elementor-element-8291bb3 elementor-widget elementor-widget-heading" data-id="8291bb3" data-element_type="widget" data-widget_type="heading.default">
                                 <div className="elementor-widget-container">
                                    <h2 className="elementor-heading-title elementor-size-default">Оператор персональных данных</h2>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </section>
                  
                  <section className="elementor-section elementor-top-section elementor-element elementor-element-d4987c6 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="d4987c6" data-element_type="section">
                     <div className="elementor-container elementor-column-gap-default">
                        <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-d28f52c" data-id="d28f52c" data-element_type="column">
                           <div className="elementor-widget-wrap elementor-element-populated">
                              <div className="elementor-element elementor-element-6f16a2d elementor-widget elementor-widget-text-editor" data-id="6f16a2d" data-element_type="widget" data-widget_type="text-editor.default">
                                 <div className="elementor-widget-container">
                                    <p><em>Оператор персональных данных - государственный орган, муниципальный орган, 
                                       юридическое или физическое лицо, самостоятельно или совместно с другими лицами организующие и (или) 
                                       осуществляющие обработку персональных данных, а также определяющие цели обработки персональных данных, 
                                       состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.</em></p>
                                    <p>Если вы создали информационную систему, в которой обрабатываете чью либо персональные данные, то вы Оператор персональных данных.</p>
                                    <p>Если вас привлекли для работы в информационной системе для обработки чужих персональных данных, то вы не Оператор персональных данных. </p>
                                    <p>Например: Вы компания, которая оказывает услуги по технической поддержке информационной системы или предотсавляющая услуги хостинга или ЦОД для Оператора ПДн. Тогда вы не Оператор персональных данных. Оператор персональных данных должен дать вам поручение на обработку персональных данных, а Субъект персональных данных должен включить вас в согласие на обработку персональных данных.</p>
                                    
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </section>

                  <section className="elementor-section elementor-top-section elementor-element elementor-element-adabee6 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="adabee6" data-element_type="section">
                     <div className="elementor-container elementor-column-gap-default">
                        <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b034990" data-id="b034990" data-element_type="column">
                           <div className="elementor-widget-wrap elementor-element-populated">
                              <div className="elementor-element elementor-element-8291bb3 elementor-widget elementor-widget-heading" data-id="8291bb3" data-element_type="widget" data-widget_type="heading.default">
                                 <div id="assignment" className="elementor-widget-container">
                                    <h2 className="elementor-heading-title elementor-size-default">Поручение на обработку персональных данных</h2>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </section>
                  
                  <section className="elementor-section elementor-top-section elementor-element elementor-element-d4987c6 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="d4987c6" data-element_type="section">
                     <div className="elementor-container elementor-column-gap-default">
                        <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-d28f52c" data-id="d28f52c" data-element_type="column">
                           <div className="elementor-widget-wrap elementor-element-populated">
                              <div className="elementor-element elementor-element-6f16a2d elementor-widget elementor-widget-text-editor" data-id="6f16a2d" data-element_type="widget" data-widget_type="text-editor.default">
                                 <div className="elementor-widget-container">
                                    <p><em>Оператор вправе поручить обработку персональных данных другому лицу с согласия субъекта персональных данных, 
                                       на основании Поручения на обработку персональных данных. 
                                        В поручении оператора должны быть определены перечень действий (операций) с персональными данными, 
                                        которые будут совершаться лицом, осуществляющим обработку персональных данных, и цели обработки, должна быть установлена обязанность такого лица соблюдать 
                                        конфиденциальность персональных данных и обеспечивать безопасность персональных данных при их обработке, а также должны быть указаны требования к защите обрабатываемых 
                                        персональных данных.</em></p>

                                    <div className="elementor-widget-container">
                                       <p>Скачайте документ <b>«Поручение на обработку персональных данных»</b> в составе пакета документов:</p>
                                       <Module_FilesDownload                                     
                                             urlPrefix={this.props.urlPrefix}
                                             PacketID="poruch"
                                             name="Поручение на обработку персональных данных"
                                          />
                                    </div>

                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </section>

                  <section className="elementor-section elementor-top-section elementor-element elementor-element-adabee6 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="adabee6" data-element_type="section">
                     <div className="elementor-container elementor-column-gap-default">
                        <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b034990" data-id="b034990" data-element_type="column">
                           <div className="elementor-widget-wrap elementor-element-populated">
                              <div className="elementor-element elementor-element-8291bb3 elementor-widget elementor-widget-heading" data-id="8291bb3" data-element_type="widget" data-widget_type="heading.default">
                                 <div id="agreement" className="elementor-widget-container">
                                    <h2 className="elementor-heading-title elementor-size-default">Согласие на обработку персональных данных</h2>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </section>
                  
                  <section className="elementor-section elementor-top-section elementor-element elementor-element-d4987c6 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="d4987c6" data-element_type="section">
                     <div className="elementor-container elementor-column-gap-default">
                        <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-d28f52c" data-id="d28f52c" data-element_type="column">
                           <div className="elementor-widget-wrap elementor-element-populated">
                              <div className="elementor-element elementor-element-6f16a2d elementor-widget elementor-widget-text-editor" data-id="6f16a2d" data-element_type="widget" data-widget_type="text-editor.default">
                                 <div className="elementor-widget-container">
                                    <p><em>Обработка персональных данных осуществляется с согласия субъекта персональных данных на обработку его персональных данных.</em></p>
                                    <p><em>Согласие на обработку персональных данных может быть дано субъектом персональных данных или его представителем в любой позволяющей подтвердить факт его получения форме, если иное не установлено федеральным законом.</em></p>
                                    <p>Например, пользователь, он же Субъект ПДн в программе может отметить галочкой, что он дал согласие.</p>
                                    <p><em>При обработке специальной категории или биометрических ПДн, обработка персональных данных осуществляется только с согласия <b>в письменной форме</b> субъекта персональных данных.</em></p>

                                    <p>Согласие в письменной форме субъекта персональных данных на обработку его персональных данных должно включать в себя, в частности:</p>
                                    <ol>
                                       <li>фамилию, имя, отчество, адрес субъекта персональных данных, номер основного документа, удостоверяющего его личность, сведения о дате выдачи указанного документа и выдавшем его органе;</li>
                                       <li>фамилию, имя, отчество, адрес представителя субъекта персональных данных, номер основного документа, удостоверяющего его личность, сведения о дате выдачи указанного документа и выдавшем его органе, реквизиты доверенности или иного документа, подтверждающего полномочия этого представителя (при получении согласия от представителя субъекта персональных данных);</li>
                                       <li>наименование или фамилию, имя, отчество и адрес оператора, получающего согласие субъекта персональных данных;</li>
                                       <li>цель обработки персональных данных;</li>
                                       <li>перечень персональных данных, на обработку которых дается согласие субъекта персональных данных;</li>
                                       <li>наименование или фамилию, имя, отчество и адрес лица, осуществляющего обработку персональных данных по поручению оператора, если обработка будет поручена такому лицу;</li>
                                       <li>перечень действий с персональными данными, на совершение которых дается согласие, общее описание используемых оператором способов обработки персональных данных;</li>
                                       <li>срок, в течение которого действует согласие субъекта персональных данных, а также способ его отзыва, если иное не установлено федеральным законом;</li>
                                       <li>подпись субъекта персональных данных.</li>
                                    </ol>
                                    <div className="elementor-widget-container">
                                       <p>Скачайте документ <b>«Согласие субъекта персональных данных»</b> в составе пакета документов:</p>
                                       <Module_FilesDownload                                     
                                             urlPrefix={this.props.urlPrefix}
                                             PacketID="sogl"
                                             name="Согласие субъекта персональных данных"
                                          />
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </section>

                  <section className="elementor-section elementor-top-section elementor-element elementor-element-adabee6 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="adabee6" data-element_type="section">
                     <div className="elementor-container elementor-column-gap-default">
                        <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b034990" data-id="b034990" data-element_type="column">
                           <div className="elementor-widget-wrap elementor-element-populated">
                              <div className="elementor-element elementor-element-8291bb3 elementor-widget elementor-widget-heading" data-id="8291bb3" data-element_type="widget" data-widget_type="heading.default">
                                 <div id="documents" className="elementor-widget-container">
                                    <h2 className="elementor-heading-title elementor-size-default">Пакет документов по персональным данным</h2>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </section>
                  
                  <section className="elementor-section elementor-top-section elementor-element elementor-element-d4987c6 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="d4987c6" data-element_type="section">
                     <div className="elementor-container elementor-column-gap-default">
                        <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-d28f52c" data-id="d28f52c" data-element_type="column">
                           <div className="elementor-widget-wrap elementor-element-populated">
                              <div className="elementor-element elementor-element-6f16a2d elementor-widget elementor-widget-text-editor" data-id="6f16a2d" data-element_type="widget" data-widget_type="text-editor.default">
                                 <div className="elementor-widget-container">
                                    <p>Для обеспечения соответствия законодательству по обработке персональных данных, вам необходимо:</p>
                                    <ul>
                                       <li>подготовить пакет документов по обработке персональных данных;</li>
                                       <li>выпустить приказ по предприятию для утверждения документов.</li>
                                    </ul>
                                    <p>Мы подготовили для вас заполненные шаблоны для кадровой учетной системы.</p>
                                    <p>Обратите внимание, что документы нужно не только утвердить, но и выполнять то, что в них написано!</p>
                                 </div>

                                <div className="elementor-widget-container">
                                  <p>Скачайте <b>«Локальные нормативные акты по персональным данным»</b> в составе пакета документов:</p>
                                  <Module_FilesDownload                                     
                                       urlPrefix={this.props.urlPrefix}
                                       PacketID="is1"         
                                       name="Локальные нормативные акты по персональным данным"                              
                                     />
                                </div>        
                            </div>
                            <br/>
                          </div>
                      </div>
                    </div>
                    </section>                  


                  <section className="elementor-section elementor-top-section elementor-element elementor-element-adabee6 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="adabee6" data-element_type="section">
                     <div className="elementor-container elementor-column-gap-default">
                        <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b034990" data-id="b034990" data-element_type="column">
                           <div className="elementor-widget-wrap elementor-element-populated">
                              <div className="elementor-element elementor-element-8291bb3 elementor-widget elementor-widget-heading" data-id="8291bb3" data-element_type="widget" data-widget_type="heading.default">
                                 <div id="notification" className="elementor-widget-container">
                                    <h2 className="elementor-heading-title elementor-size-default">Уведомление в Роскомнадзор</h2>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </section>
                  
                  <section className="elementor-section elementor-top-section elementor-element elementor-element-d4987c6 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="d4987c6" data-element_type="section">
                     <div className="elementor-container elementor-column-gap-default">
                        <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-d28f52c" data-id="d28f52c" data-element_type="column">
                           <div className="elementor-widget-wrap elementor-element-populated">
                              <div className="elementor-element elementor-element-6f16a2d elementor-widget elementor-widget-text-editor" data-id="6f16a2d" data-element_type="widget" data-widget_type="text-editor.default">
                                 <div className="elementor-widget-container">
<p>С 1 сентября 2022 года каждое юридическое лицо или ИП должны уведомить Роскомнадзор об обработке персональных данных своих работников, если персональные данные обрабатываются в информационной системе, например, в «1С:Бухгалтерия», «Парус», «Мое Дело» и любых других бухгалтерских программах.</p>
<p>Раньше, до вступления в силу изменений в Федеральный закон N152 "О персональных данных", такое уведомление делать было не нужно, если компания обрабатывала персональные данные своих работников.</p>
<p>Если в вашей компании персональные данные обрабатываются без использования информационной системы – на бумаге, то для вас ничего не изменилось. Федеральный закон N152 "О персональных данных" на вас по прежнему не распространяется.</p>
<p>Если персональные данные обрабатываются без использования информационной системы, такое уведомление делать не нужно.</p>
<p>Если у вас есть любая информационная система, в которую персональные данные вносятся, нужно подать уведомление в Роскомнадзор о начале обработки персональных данных.</p>
<p>Мы подготовили для вас заполненный шаблон для кадровой учетной системы.</p>
<p>Скачайте документ <b>«Уведомление в Роскомнадзор»</b> в составе пакета документов:</p>
                                  <Module_FilesDownload                                     
                                       urlPrefix={this.props.urlPrefix}
                                       PacketID="uved1"
                                       name="Уведомление в Роскомнадзор"
                                     />


                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </section>
       
        
                    <section className="elementor-section elementor-top-section elementor-element elementor-element-17e1846 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="17e1846" data-element_type="section">
                    <div className="elementor-container elementor-column-gap-default">
                      <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-9afa6a4" data-id="9afa6a4" data-element_type="column">
                          <div className="elementor-widget-wrap elementor-element-populated">

                            <div className="elementor-element elementor-element-37c1af4 elementor-widget elementor-widget-text-editor" data-id="37c1af4" data-element_type="widget" data-widget_type="text-editor.default">
                                <div className="elementor-widget-container">

                                     <h3 className="elementor-heading-title elementor-size-default">Что дальше?</h3> 
                                     <br/>
                                     <p>Для того чтобы ещё подробнее разобраться с персональными данными и обеспечением их информационной безопасности, читайте также страницы нашего сайта:</p>
                                    <ul>
                                    <li>как определить<a href="/personal-data/security-level/"> уровень защищенности информационной системы персональных данных и сделать соответсвующий акт</a>.</li>
                                    </ul>


                                </div>

                            </div>
                                                    

                                  <br/>
                          </div>
                      </div>
                    </div>
                    </section>                  

               </div>
            </div>
            <div id="av-secondary-content" className="av-column-4">
               <section className="sidebar">
               </section>
            </div>
         </div>
      </div>
   </section>
</div>
</div>
    );
  }
};

export default Main;
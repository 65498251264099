import React from 'react';
import { getUserID,getData } from './getData';
import Header_Menu from './Header_Menu.js';

 
class Main extends React.Component {
  constructor(props) {
    super(props);
    //this.ClientID;
    this.state = {
      //leadColor:"red",
      ClientID:'',
    }
  }
  componentDidMount() {
    var ClientID=this.state.ClientID;
    if (!ClientID) 
    {
      ClientID=getUserID();
      this.setState({ ClientID:ClientID});     
      
    };

   var sum_data={};
   sum_data['ClientID']=ClientID;
  
   
  }

  //this.setReState.bind(this);  
  render() {
   //console.log(this.state.question);
   //console.log(this.ClientID); 
    return (
<div>

<section id="breadcrumb-section" className="breadcrumb-area breadcrumb-left действующий эффект хлебных крошек">
	<div className="av-container">
		<div className="av-columns-area">
			<div className="av-column-12">
				<div className="breadcrumb-content">
					<div className="breadcrumb-heading wow fadeInLeft" style={{"visibility": "visible", "animationName": "fadeInLeft"}}>
						<h1>Профиль пользователя</h1>	
					</div>
               <div className="breadcrumb-list_" >

               <Header_Menu/>
					<ol className="breadcrumb-list wow fadeInRight" style={{"visibility": "visible", "animationName": "fadeInRight"}}>
						<li>
                     <a href="/">Информационная безопасность</a>
                  </li>
               </ol>
               </div>
				</div>
			</div>
		</div>
	</div>
</section>


<div id="content" className="gradiant-content">
   <section id="post-section" className="post-section av-py-default">
      <div className="av-container">
         <div className="av-columns-area">
            <div id="av-primary-content" className="av-column-12">
               <div data-elementor-type="wp-page" data-elementor-id="108" className="elementor elementor-108">
                  
                  <section className="elementor-section elementor-top-section elementor-element elementor-element-83cfe99 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="83cfe99" data-element_type="section">
                     <div className="elementor-container elementor-column-gap-default">
                        <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b13d148" data-id="b13d148" data-element_type="column">
                           <div className="elementor-widget-wrap elementor-element-populated">
                              <div className="elementor-element elementor-element-7027b68 elementor-widget elementor-widget-heading" data-id="7027b68" data-element_type="widget" data-widget_type="heading.default">
                                 <div className="elementor-widget-container">
                                    <h2 className="elementor-heading-title elementor-size-default">Идентификатор пользователя</h2>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </section>
                  <section className="elementor-section elementor-top-section elementor-element elementor-element-d4987c6 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="d4987c6" data-element_type="section">
                     <div className="elementor-container elementor-column-gap-default">
                        <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-d28f52c" data-id="d28f52c" data-element_type="column">
                           <div className="elementor-widget-wrap elementor-element-populated">
                              <div className="elementor-element elementor-element-6f16a2d elementor-widget elementor-widget-text-editor" data-id="6f16a2d" data-element_type="widget" data-widget_type="text-editor.default">
                                 <div className="elementor-widget-container">
                                    <p>Это ваш идентификатор, добавьте его в <b><a href="https://t.me/MindStepBot">Телеграм-бот</a></b>, чтобы заказ не потерялся и мы знали кого оповещать:</p>
                                    <p><b>{this.state.ClientID}</b></p>
                                    <p>Спамить не будем!</p>
                                    
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </section>

               </div>
            </div>
            <div id="av-secondary-content" className="av-column-4">
               <section className="sidebar">
               </section>
            </div>
         </div>
      </div>
   </section>
</div>
</div>
    );
  }
};

 

export default Main;
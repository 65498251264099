import React from 'react';
import { NavLink } from 'react-router-dom';
import { Link, animateScroll as scroll } from "react-scroll";

   
      class Header extends React.Component {
  constructor(props) {
    super(props);
    //this.
    this.url=undefined;
    this.state = {
      redirect: 1,
      menu:null,
      selectedMenu:'/',
    };
  }

  render() {

    return (

<div className="theme-menu">
	<div className="menu-right">
		<ul className="header-wrap-right">										
			<li className="widget-wrap">
				<div className="widget-wrp">
					<aside className="widget widget-contact">
						<div className="contact-area ct-area1">
							<div className="contact-icon">
								<a href="/profile" className="contact-info">	
									<i className="fa fa-user" aria-hidden="true"></i>
								</a>
							</div>
							<div className="contact-info">
								<a href="/profile" className="contact-info">
									<span className="text">Профиль</span>
								</a>
							</div>					

						</div>
					</aside>
				</div>
			</li>
			<li className="widget-wrap">
				<div className="widget-wrp">
					<aside className="widget widget-contact">
						<div className="contact-area ct-area1">
							<div className="contact-icon">
								<a href="/orders" className="contact-info">
									<i className="fa fa-shopping-basket" aria-hidden="true"></i>								
								</a>
							</div>
							<div className="contact-info">
								<a href="/orders" className="contact-info">
									<span className="text">Заказы </span>
								</a>
							</div>					

						</div>
					</aside>
				</div>
			</li>
		</ul>
	</div>
</div>

    );
  }
}
export default Header;

import React from 'react';
import { getUserID,getData } from './getData';
import Header_Menu from './Header_Menu.js';
import { Space, Table, Tag } from 'antd';
import Module_FilesDownload from './Module_FilesDownload.js';
var url="";
const columns = [
   {
     title: '№',
     dataIndex: 'key',
     key: 'key',
     
     
   },
   {
      title: 'Действие',
      dataIndex: 'description',
      key: 'description',
      render: (text, record, index) =>
      {
       //console.log(record);
       return(         
       
         <Module_FilesDownload
         urlPrefix={url}
         PacketID="all"       
         name="Все документы портала"
       />

       ) 
      }      
    },         
   {
     title: 'GUID',
     dataIndex: 'object_id',
     key: 'object_id',
     /*
     render: (text, record, index) =>
     {
      //console.log(record);
      return(<a target="_blank" href={record.confirmation_url}>{text}</a>) 
     }
     */
     
   },
   {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
    },   
    {
      title: 'Стоимость',
      dataIndex: 'amount_value',
      key: 'amount_value',
    },      {
      title: 'Валюта',
      dataIndex: 'amount_currency',
      key: 'amount_currency',
    },      {
      title: 'Описание',
      dataIndex: 'description',
      key: 'description',
    },      {
      title: 'Дата создания',
      dataIndex: 'created_at_',
      key: 'created_at_',
    }
   
 ];
 
class Main extends React.Component {
  constructor(props) {
    super(props);
    //this.ClientID;
    this.state = {
      //leadColor:"red",
      ClientID:'',
      data:[],
    }
    this.getResult = this.getResult.bind(this);  
  }
  getResult(data){
   //console.log(data);
   this.setState({ data:data.data});     
  }
  componentDidMount() {
   var ClientID=this.state.ClientID;
   if (!ClientID) 
   {
     ClientID=getUserID();
     this.setState({ ClientID:ClientID});     
     
   };

  var sum_data={};
  sum_data['ClientID']=ClientID;
 
  getData(this.props.urlPrefix+"/order/?ClientID="+ClientID,this.getResult);
 }

  //this.setReState.bind(this);  
  render() {
   //console.log(this.state.data.length);
   //console.log(this.ClientID); 
      url=this.props.urlPrefix;
      if (this.state.data.length==0)
      return (
<div>

<section id="breadcrumb-section" className="breadcrumb-area breadcrumb-left действующий эффект хлебных крошек">
	<div className="av-container">
		<div className="av-columns-area">
			<div className="av-column-12">
				<div className="breadcrumb-content">
					<div className="breadcrumb-heading wow fadeInLeft" style={{"visibility": "visible", "animationName": "fadeInLeft"}}>
						<h1>Заказы пользователя</h1>	
					</div>
               <div className="breadcrumb-list_" >

               <Header_Menu/>
					<ol className="breadcrumb-list wow fadeInRight" style={{"visibility": "visible", "animationName": "fadeInRight"}}>
						<li>
                     <a href="/">Информационная безопасность</a>
                  </li>
               </ol>
               </div>
				</div>
			</div>
		</div>
	</div>
</section>
<div id="content" className="gradiant-content">
   <section id="post-section" className="post-section av-py-default">
      <div className="av-container">
         <div className="av-columns-area">
            <div id="av-primary-content" className="av-column-12">
               <div data-elementor-type="wp-page" data-elementor-id="108" className="elementor elementor-108">
                  
                  <section className="elementor-section elementor-top-section elementor-element elementor-element-83cfe99 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="83cfe99" data-element_type="section">
                     <div className="elementor-container elementor-column-gap-default">
                        <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b13d148" data-id="b13d148" data-element_type="column">
                           <div className="elementor-widget-wrap elementor-element-populated">
                              <div className="elementor-element elementor-element-7027b68 elementor-widget elementor-widget-heading" data-id="7027b68" data-element_type="widget" data-widget_type="heading.default">
                                 <div className="elementor-widget-container">
                                    <h2 className="elementor-heading-title elementor-size-default">Заказы пользователя</h2>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </section>

               </div>
            </div>
            <div id="av-secondary-content" className="av-column-4">
               <section className="sidebar">
               
               </section>
            </div>
            
         </div>
         <br/>
         <p>Вы можете купить пакет документов в редактируемом виде в формате Word.</p>
         <p>Стоимость пакета документа 3 000 рублей, это чуть меньше среднего чека вашего похода в магазин.</p>
         <p>Для покупки вы будете перенаправлены на сайт Яндекс.Касса.</p>
         <p>Состав документов в пакете:</p>
         <ul>
         <li>Локальные нормативные акты:</li>
         <ul>
         <li>Политика обработки ПДн;</li>
         <li>Политика антивирусной защиты;</li>
         <li>Политика парольной защиты;</li>
         <li>Политика управления и контроля доступа;</li>
         <li>Политика мониторинга событий и управления инцидентами ИБ;</li>
         <li>Политика управления обновлениями и контроля уязвимостей;</li>
         <li>Положение об обработке ПДн;</li>
         <li>Положение по организации работ по защите ПДн;</li>
         <li>Порядок взимодействия с уполномоченным органом;</li>
         <li>Порядок контроля защищенности ПДн;</li>
         <li>Порядок обработки обращений субъектов ПДн;</li>
         <li>Порядок осуществления субъектом ПДн прав;</li>
         <li>Процедура управления изменениями в ИС;</li>
         <li>Приказ об организации работ по обеспечению безопасности ПДн;</li>
         </ul>
         <li>Акт определения уровня защищенности ПДн;</li>
         <li>Уведомление в Роскомнадзор.</li>
         </ul>
         
         <Module_FilesDownload
         urlPrefix={url}
         PacketID="all"       
         name="Все документы портала"
       />
         
         
      </div>
      
   </section>
   
</div>
</div>


      )  
    return (
<div>

<section id="breadcrumb-section" className="breadcrumb-area breadcrumb-left действующий эффект хлебных крошек">
	<div className="av-container">
		<div className="av-columns-area">
			<div className="av-column-12">
				<div className="breadcrumb-content">
					<div className="breadcrumb-heading wow fadeInLeft" style={{"visibility": "visible", "animationName": "fadeInLeft"}}>
						<h1>Заказы пользователя</h1>	
					</div>
               <div className="breadcrumb-list_" >

               <Header_Menu/>
					<ol className="breadcrumb-list wow fadeInRight" style={{"visibility": "visible", "animationName": "fadeInRight"}}>
						<li>
                     <a href="/">Информационная безопасность</a>
                  </li>
               </ol>
               </div>
				</div>
			</div>
		</div>
	</div>
</section>


<div id="content" className="gradiant-content">
   <section id="post-section" className="post-section av-py-default">
      <div className="av-container">
         <div className="av-columns-area">
            <div id="av-primary-content" className="av-column-12">
               <div data-elementor-type="wp-page" data-elementor-id="108" className="elementor elementor-108">
                  
                  <section className="elementor-section elementor-top-section elementor-element elementor-element-83cfe99 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="83cfe99" data-element_type="section">
                     <div className="elementor-container elementor-column-gap-default">
                        <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b13d148" data-id="b13d148" data-element_type="column">
                           <div className="elementor-widget-wrap elementor-element-populated">
                              <div className="elementor-element elementor-element-7027b68 elementor-widget elementor-widget-heading" data-id="7027b68" data-element_type="widget" data-widget_type="heading.default">
                                 <div className="elementor-widget-container">
                                    <h2 className="elementor-heading-title elementor-size-default">Заказы пользователя</h2>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </section>

               </div>
            </div>
            <div id="av-secondary-content" className="av-column-4">
               <section className="sidebar">
               
               </section>
            </div>
            
         </div>
         <br/>
         
            

         <Table columns={columns} dataSource={this.state.data} />
         
         
      </div>
      
   </section>
   
</div>
</div>
    );
  }
};

 

export default Main;

/*
                  <section className="elementor-section elementor-top-section elementor-element elementor-element-d4987c6 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="d4987c6" data-element_type="section">
                     <div className="elementor-container elementor-column-gap-default">
                        <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-d28f52c" data-id="d28f52c" data-element_type="column">
                           <div className="elementor-widget-wrap elementor-element-populated">
                              <div className="elementor-element elementor-element-6f16a2d elementor-widget elementor-widget-text-editor" data-id="6f16a2d" data-element_type="widget" data-widget_type="text-editor.default">
                                 <div className="elementor-widget-container">
                                    <p>Это ваш идентификатор, сохраните его на всякий случай:</p>
                                    <p><b>{this.state.ClientID}</b></p>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </section>

*/
import React from 'react';
import { NavLink } from 'react-router-dom';

import logo2 from '../imgs/logo2.png';

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.email="";
    this.subscribeType="";
  }


  render() {
    return (
<footer id="footer-section" className="footer-two footer-section  действующий эффект подвала">
					<div className="footer-copyright">
				<div className="av-container">
					<div className="av-columns-area">
							<div className="av-column-4 av-md-column-6 text-md-left text-center">
								<div className="widget-left">
																			<div className="logo">
											<a href="/" className="site-title"><img src={logo2}/></a>
										</div>
																	</div>
							</div>
							<div className="av-column-4 av-md-column-6 text-md-center text-center">

							
								<div className="widget-center">
									<p>Это то, что вы искали?<br/>Для обратной связи напишите <br/>в <a href="https://t.me/MindStepBot">Телеграм-бот</a></p>
								</div>
							</div>
							<div className="av-column-4 av-md-column-6 text-av-right text-md-right text-center">
								<div className="widget-right">                          
									                        
									<div className="copyright-text">
										Copyright © 2022 <br/>Автоматизируй это!<br/>
										<div className="version">1.1.2.1</div>
										<div className="version">Кондратенко Андрей Александрович</div>
										<div className="version">ИНН: 504791186490</div>
									</div>
								</div>
							</div>
					</div>
				</div>
			</div>
      
		    </footer>
        
      );
  }
}
export default Footer;
/*
<NavLink to="/dictionary/" className="menu_footer">Словарь</NavLink>
<NavLink to="/map/" className="menu_footer">Карта сайта</NavLink>
*/
import React from 'react';
import { Switch, Spin, Drawer,Button,Modal, Form, Input, Select } from 'antd';
import { isStateMemberExpression } from 'eslint-plugin-react/lib/util/componentUtil';
import { getData,postData,getUserID,downloadFile } from './getData';
import {ConstPersonalData} from './Const_Personal_Data.js';

import {  CheckCircleOutlined  }  from '@ant-design/icons';
//const {  DownloadOutlined  } = icons;

 
const IsLoading = (props) => {
  //console.log(props);
  return((props.loading)?<Spin tip="Загрузка данных..."/>:<div/>);
  //<div className="ant-spin ant-spin-spinning ant-spin-show-text"/>
}


class Module_DemoDoc extends React.Component {
  constructor(props) {
    super(props);
    this.ClientID;
    this.state = {
      loading:false,
      
   }
    this.onClick = this.onClick.bind(this);
  }

  onClick(e) {
    let demoURL=this.props.urlPrefix+"/Docs/demoPDF.zip";
    this.setState({ loading:true });
    
    fetch(demoURL)
    .then(resp => resp.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      // the filename you want
      a.download = "Демо.zip";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      this.setState({ loading:false });
      //alert('your file has downloaded!'); // or you know, something with better UX...
    })
    .catch(() => {
      alert('Все сломалось... Файл не загрузился...')
      this.setState({ loading:false });
    });    
    
  }

  //this.setReState.bind(this);  
  render() {
   //console.log(this.state);
   var size="default";
    return (
      <div className="button_center">
        <Button icon={<CheckCircleOutlined />} disabled={this.state.loading} type="primary" shape="round"  size={size} onClick={this.onClick} >
        
        Демо
        </Button> 
        <Button type="primary" icon={<CheckCircleOutlined />} size={size} />
      <Button type="primary" shape="circle" icon={<CheckCircleOutlined />} size={size} />

        <IsLoading loading={this.state.loading}/>        
      </div>
    );
  }
};

export default Module_DemoDoc;
import React from 'react';
import ReactDOM from 'react-dom';


import 'antd/dist/antd.min.css';

import './css/wordpress.css';

/*
import './css/global.css';
import './css/frontend-lite.min.css';
import './css/theme.css';

import './css/editor-style.css';
import './css/meanmenu.css';
import './css/widgets.css';
import './css/main.css';
import './css/style.css';
import './css/post-6.css';
import './css/post-108.css';

*/
import './css/style_overflow.css';

//import WebFont from './js/outer/webfont.js';
import App from './js/index';

ReactDOM.render(
      <App />,
document.getElementById("root"));

export default App;

import React from 'react';
import { Switch, Spin, Drawer } from 'antd';
import { isStateMemberExpression } from 'eslint-plugin-react/lib/util/componentUtil';
import { getData,postData,getUserID,downloadFile } from './getData';
import {ConstPersonalData} from './Const_Personal_Data.js';
import Header_Menu from './Header_Menu.js';

import Module_FilesDownload from './Module_FilesDownload.js';

/*
function spinMove (b){
  //div-block-device-search-new
  return((!openWindow)?"div-block-device-search-new":"div-block-dvice-search-result");
}
*/
const IsLoading = (props) => {
  console.log(props);
  return((props.loading)?<Spin tip="Загрузка данных..."/>:<div style={{padding: "24px"}}/>);
  return(
  <Drawer title="Basic Drawer" placement="right"  visible={props.loading}>
  <p>Some contents...</p>
  <p>Some contents...</p>
  <p>Some contents...</p>
</Drawer>
  );
}



const SecurityChoiceLevel = (props) => {
  //console.log(ConstPersonalData[security_choice_level1]);
  return (ConstPersonalData[props.security_choice_level].value );
  };
 
class Main extends React.Component {
  constructor(props) {
    super(props);
    this.ClientID;
    this.state = {
      question:[],
      answer:0,
      loading:true,
   }

    this.onChange = this.onChange.bind(this);
    this.onClick = this.onClick.bind(this);
    this.getFileDownload = this.getFileDownload.bind(this);
    
    this.getResultSecLevel = this.getResultSecLevel.bind(this);  
  }
  componentDidMount() {
    if (!this.ClientID) this.ClientID=getUserID(); 
   let question=[];
   question[1]=0;
   question[2]=0;
   question[3]=0;
   question[4]=0;

   this.setState({ question: question });
   getData(this.props.urlPrefix+"/seclevel/"+this.ClientID,this.getResultSecLevel);
   this.setState({ loading:true });
  }
  getFileDownload(data)
  {
    //console.log(data);
    
    
    if (data.status=="success")
    {
      //window.open(this.props.urlPrefix+"/makedoc/act1?file_name="+data.data.file_name, '_blank');
      downloadFile(data.data.url, "Акт определения уровня защищенности ПДн.zip");
    } else
    {
      
    }
    

  }
  onClick(e) {
    
    
    var sum_data={};
    sum_data['ClientID']=this.ClientID;
    postData(this.props.urlPrefix+"/makedoc/act1/",this.ClientID,this.getFileDownload,sum_data);
    /*
    if (!this.ClientID) this.ClientID=getUserID(); 
   let question=[];
   question[1]=0;
   question[2]=0;
   question[3]=0;
   question[4]=0;

   this.setState({ question: question });
   getData(this.props.urlPrefix+"/seclevel/"+this.ClientID,this.getResultSecLevel);
   this.setState({ loading:true });
   */
  }
  
  
  getResultSecLevel(data){
    //console.log(data);
    let question=[];
    question[1]=data.data.ispdn_type;
    question[2]=data.data.subject_pdn_type;
    question[3]=data.data.subject_pdn_count;
    question[4]=data.data.actual_threats_count;
 
    this.setState({ answer: data.data.security_choice_level,question: question,loading:false });
    
    // Нужно новое окно!
    // Если все хорошо, закрываем окно!
    /*
    if (data.data.state==1)
    {
      this.props.handleWindowCloseRegistration();
      this.setState({ resPopup:true,resMessage: data.data.infoToClient,resType: data.data.state });

      this.setState(clear);
      this.props.handleReadUserInfo(); // Перечитываем имя пользователя
  
    }
    else
    {
      if ((data.data.infoToClient=='')||(data.data.infoToClient==null))
      {
        this.setState({ globalError:data.message});
      }
      else
      {
        this.setState({ globalError:data.data.infoToClient});
      }
    };
    */
      
    // infoToClient - Сообщение
    //$result['state']=0 - есть проблемы; 1 - все получилось    
  };

onChange(checked,e){
   //console.log(e.target);
   const target=e.target;
   //const id = target.id;
   let index=target.id.indexOf('_');
   let id=target.id.slice(index+1);
       //index=id.lastIndexOf('_');
   let idFirst=id.charAt(0);
   let idSecond=id.charAt(2);
   if (id!='')
   {
      //this.setState({ question1: id });
      let question=this.state.question;
      question[idFirst]=parseInt(idSecond);
      this.setState({ question: question });

      var sum_data={};
      /*
      question.forEach( element => {
          console.log(element);
          //sum_data[element.name]=element.value;
        });
      */
      sum_data['ispdn_type']=question[1];
      sum_data['subject_pdn_type']=question[2];
      sum_data['subject_pdn_count']=question[3];
      sum_data['actual_threats_count']=question[4];
      //console.log(this.ClientID);
      postData(this.props.urlPrefix+"/seclevel/",this.ClientID,this.getResultSecLevel,sum_data);
      this.setState({ loading:true });
   }
   //console.log(id);
   //console.log(index);
   //console.log(idFirst);
   //console.log(idSecond);
 };
  //this.setReState.bind(this);  
  render() {
   //console.log(this.state.question);
   
    return (
<div>

<section id="breadcrumb-section" className="breadcrumb-area breadcrumb-left действующий эффект хлебных крошек">
	<div className="av-container">
		<div className="av-columns-area">
			<div className="av-column-12">
				<div className="breadcrumb-content">
					<div className="breadcrumb-heading wow fadeInLeft" style={{"visibility": "visible", "animationName": "fadeInLeft"}}>
						<h1>Уровни защищенности персональных данных</h1>	
					</div>
               <div className="breadcrumb-list_" >

               <Header_Menu/>
					<ol className="breadcrumb-list wow fadeInRight" style={{"visibility": "visible", "animationName": "fadeInRight"}}>
						<li>
                     <a href="/">Информационная безопасность</a> &nbsp;-&nbsp;
                  </li>
                  <li className="active">
                     <a href="/personal-data/">Персональные данные</a>
                  </li>					
               </ol>
               </div>
				</div>
			</div>
		</div>
	</div>
</section>


<div id="content" className="gradiant-content">
   <section id="post-section" className="post-section av-py-default">
      <div className="av-container">
         <div className="av-columns-area">
            <div id="av-primary-content" className="av-column-12">
               <div data-elementor-type="wp-page" data-elementor-id="108" className="elementor elementor-108">
                  
                  <section className="elementor-section elementor-top-section elementor-element elementor-element-d4987c6 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="d4987c6" data-element_type="section">
                     <div className="elementor-container elementor-column-gap-default">
                        <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-d28f52c" data-id="d28f52c" data-element_type="column">
                           <div className="elementor-widget-wrap elementor-element-populated">
                              <div className="elementor-element elementor-element-6f16a2d elementor-widget elementor-widget-text-editor" data-id="6f16a2d" data-element_type="widget" data-widget_type="text-editor.default">
                                 <div className="elementor-widget-container">
                                    <p>Защиту персональных данных необходимо начать с определения уровня защищенности персональных данных, потому что вся защита персональных данных строится исходя из уровня защищенности, зафиксированного в акте определения уровня защищенности ИСПДн.</p>
                                    <p>Уровень защищенности ИСПДн определяется в соответствии с постановлением Правительства РФ №1119 "Об утверждении требований к защите персональных данных при их обработке в информационных системах персональных данных".</p>
                                    <p>Расположим критерии, определяющие уровни защищенности от наиболее защищенного к наименее защищенному.</p>
                                    <p>Чем меньше у вас уровень защищенности тем лучше.</p>
                                    
                                    <p>Выберите селекторы, руководствуясь подсказками:</p>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </section>
                  
                  <section className="elementor-section elementor-top-section elementor-element elementor-element-83cfe99 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="83cfe99" data-element_type="section">
                     <div className="elementor-container elementor-column-gap-default">
                        <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b13d148" data-id="b13d148" data-element_type="column">
                           <div className="elementor-widget-wrap elementor-element-populated">
                              <div className="elementor-element elementor-element-7027b68 elementor-widget elementor-widget-heading" data-id="7027b68" data-element_type="widget" data-widget_type="heading.default">
                                 <div className="elementor-widget-container">
                                    <h2 className="elementor-heading-title elementor-size-default">Категории ПДн</h2>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </section>
                  <section className="elementor-section elementor-top-section elementor-element elementor-element-d4987c6 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="d4987c6" data-element_type="section">
                     <div className="elementor-container elementor-column-gap-default">
                        <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-d28f52c" data-id="d28f52c" data-element_type="column">
                           <div className="elementor-widget-wrap elementor-element-populated">
                              <div className="elementor-element elementor-element-6f16a2d elementor-widget elementor-widget-text-editor" data-id="6f16a2d" data-element_type="widget" data-widget_type="text-editor.default">
                                 <div className="elementor-widget-container">
                                    <p>Чем ниже вы по списку категорий ПДн, там меньше у вас будет уровень защищенности.</p>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </section>

                  <section className="elementor-section elementor-top-section elementor-element elementor-element-4c423ba elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="4c423ba" data-element_type="section">
                     <div className="elementor-container elementor-column-gap-default">
                        <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-439b5a5" data-id="439b5a5" data-element_type="column">
                           <div className="elementor-widget-wrap elementor-element-populated">
                              <section className="elementor-section elementor-inner-section elementor-element elementor-element-03f91c9 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="03f91c9" data-element_type="section">
                                 <div className="elementor-container elementor-column-gap-default">
                                    <div className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-58d1c2f" data-id="58d1c2f" data-element_type="column">
                                       <div className="elementor-widget-wrap elementor-element-populated">
                                          <div className="elementor-element elementor-element-87957f7 elementor-widget elementor-widget-text-editor" data-id="87957f7" data-element_type="widget" data-widget_type="text-editor.default">
                                             <div className="elementor-widget-container">
                                                <h3>Специальные</h3>
                                                 <Switch id="question_1_1" checked={(this.state.question[1]==1)} onChange={this.onChange} />

                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-5f6c4e0" data-id="5f6c4e0" data-element_type="column">
                                       <div className="elementor-widget-wrap elementor-element-populated">
                                          <div className="elementor-element elementor-element-27604f9 elementor-widget elementor-widget-text-editor" data-id="27604f9" data-element_type="widget" data-widget_type="text-editor.default">
                                             <div className="elementor-widget-container">
                                                <p>ИС является информационной системой, обрабатывающей специальные категории персональных данных, если в ней обрабатываются персональные данные, касающиеся расовой, национальной принадлежности, политических взглядов, религиозных или философских убеждений, состояния здоровья, интимной жизни субъектов персональных данных.</p>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-bc9768b" data-id="bc9768b" data-element_type="column">
                                       <div className="elementor-widget-wrap elementor-element-populated">
                                          <div className="elementor-element elementor-element-001280d elementor-widget elementor-widget-text-editor" data-id="001280d" data-element_type="widget" data-widget_type="text-editor.default">
                                             <div className="elementor-widget-container">
                                                <p><em>Вы медицинская организация? Тогда это ваша категория. В остальных случаях попытайтесь не обрабатывать указанные персональные данные автоматизированным способом.</em></p>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </section>
                           </div>
                        </div>
                     </div>
                  </section>
                  <section className="elementor-section elementor-top-section elementor-element elementor-element-dd4ce79 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="dd4ce79" data-element_type="section">
                     <div className="elementor-container elementor-column-gap-default">
                        <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-55a3563" data-id="55a3563" data-element_type="column">
                           <div className="elementor-widget-wrap elementor-element-populated">
                              <section className="elementor-section elementor-inner-section elementor-element elementor-element-cb3f928 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="cb3f928" data-element_type="section">
                                 <div className="elementor-container elementor-column-gap-default">
                                    <div className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-076fe93" data-id="076fe93" data-element_type="column">
                                       <div className="elementor-widget-wrap elementor-element-populated">
                                          <div className="elementor-element elementor-element-fec6826 elementor-widget elementor-widget-text-editor" data-id="fec6826" data-element_type="widget" data-widget_type="text-editor.default">
                                             <div className="elementor-widget-container">
                                                <h3>Биометрические</h3>
                                                <Switch id="question_1_2" checked={(this.state.question[1]==2)}  onChange={this.onChange} />
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-3e7f158" data-id="3e7f158" data-element_type="column">
                                       <div className="elementor-widget-wrap elementor-element-populated">
                                          <div className="elementor-element elementor-element-9d6426b elementor-widget elementor-widget-text-editor" data-id="9d6426b" data-element_type="widget" data-widget_type="text-editor.default">
                                             <div className="elementor-widget-container">
                                                <p>ИС является информационной системой, обрабатывающей биометрические персональные данные, если в ней обрабатываются сведения, которые характеризуют физиологические и биологические особенности человека, на основании которых можно установить его личность и которые используются оператором для установления личности субъекта персональных данных, и не обрабатываются сведения, относящиеся к специальным категориям персональных данных.</p>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-6c8ec15" data-id="6c8ec15" data-element_type="column">
                                       <div className="elementor-widget-wrap elementor-element-populated">
                                          <div className="elementor-element elementor-element-63d6299 elementor-widget elementor-widget-text-editor" data-id="63d6299" data-element_type="widget" data-widget_type="text-editor.default">
                                             <div className="elementor-widget-container">
                                                <p><em>Если вы обрабатываете отпечатки пальцев и используете их для аутентификации в ИСПДн или используете распознавание лиц, значит это ваша категория.</em></p>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </section>
                           </div>
                        </div>
                     </div>
                  </section>
                  <section className="elementor-section elementor-top-section elementor-element elementor-element-13f4e9b elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="13f4e9b" data-element_type="section">
                     <div className="elementor-container elementor-column-gap-default">
                        <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-853eba0" data-id="853eba0" data-element_type="column">
                           <div className="elementor-widget-wrap elementor-element-populated">
                              <section className="elementor-section elementor-inner-section elementor-element elementor-element-eacaecf elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="eacaecf" data-element_type="section">
                                 <div className="elementor-container elementor-column-gap-default">
                                    <div className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-c1e40f5" data-id="c1e40f5" data-element_type="column">
                                       <div className="elementor-widget-wrap elementor-element-populated">
                                          <div className="elementor-element elementor-element-8bde2d4 elementor-widget elementor-widget-text-editor" data-id="8bde2d4" data-element_type="widget" data-widget_type="text-editor.default">
                                             <div className="elementor-widget-container">
                                                <h3>Иные</h3>
                                                <Switch id="question_1_4" checked={(this.state.question[1]==4)} onChange={this.onChange} />
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-dd77ff2" data-id="dd77ff2" data-element_type="column">
                                       <div className="elementor-widget-wrap elementor-element-populated">
                                          <div className="elementor-element elementor-element-1e7c269 elementor-widget elementor-widget-text-editor" data-id="1e7c269" data-element_type="widget" data-widget_type="text-editor.default">
                                             <div className="elementor-widget-container">
                                                <p>ИС является информационной системой, обрабатывающей иные категории персональных данных, если в ней не обрабатываются персональные данные, указанные выше.</p>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-25a3ef8" data-id="25a3ef8" data-element_type="column">
                                       <div className="elementor-widget-wrap elementor-element-populated">
                                          <div className="elementor-element elementor-element-f8c845e elementor-widget elementor-widget-text-editor" data-id="f8c845e" data-element_type="widget" data-widget_type="text-editor.default">
                                             <div className="elementor-widget-container">
                                                <p><em>Но скорее всего у вас именно это категория</em></p>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </section>
                           </div>
                        </div>
                     </div>
                  </section>
                  <section className="elementor-section elementor-top-section elementor-element elementor-element-065b517 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="065b517" data-element_type="section">
                     <div className="elementor-container elementor-column-gap-default">
                        <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-43c1427" data-id="43c1427" data-element_type="column">
                           <div className="elementor-widget-wrap elementor-element-populated">
                              <section className="elementor-section elementor-inner-section elementor-element elementor-element-07795c8 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="07795c8" data-element_type="section">
                                 <div className="elementor-container elementor-column-gap-default">
                                    <div className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-246cacc" data-id="246cacc" data-element_type="column">
                                       <div className="elementor-widget-wrap elementor-element-populated">
                                          <div className="elementor-element elementor-element-604e788 elementor-widget elementor-widget-text-editor" data-id="604e788" data-element_type="widget" data-widget_type="text-editor.default">
                                             <div className="elementor-widget-container">
                                                <h3>Общедоступные</h3>
                                                <Switch id="question_1_3" checked={(this.state.question[1]==3)} onChange={this.onChange} />
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-b6cfe3e" data-id="b6cfe3e" data-element_type="column">
                                       <div className="elementor-widget-wrap elementor-element-populated">
                                          <div className="elementor-element elementor-element-5e5c031 elementor-widget elementor-widget-text-editor" data-id="5e5c031" data-element_type="widget" data-widget_type="text-editor.default">
                                             <div className="elementor-widget-container">
                                                <p>ИС является информационной системой, обрабатывающей общедоступные персональные данные, если в ней обрабатываются персональные данные субъектов персональных данных, полученные только из общедоступных источников персональных данных.</p>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-92025bb" data-id="92025bb" data-element_type="column">
                                       <div className="elementor-widget-wrap elementor-element-populated">
                                          <div className="elementor-element elementor-element-75f120d elementor-widget elementor-widget-text-editor" data-id="75f120d" data-element_type="widget" data-widget_type="text-editor.default">
                                             <div className="elementor-widget-container">
                                                <p><em>Вы взяли персональные данные из доступных источников? Их тоже нужно защищать.</em></p>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </section>
                           </div>
                        </div>
                     </div>
                  </section>
                  <section className="elementor-section elementor-top-section elementor-element elementor-element-a860bb6 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="a860bb6" data-element_type="section">
                     <div className="elementor-container elementor-column-gap-default">
                        <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-cc2c3f2" data-id="cc2c3f2" data-element_type="column">
                           <div className="elementor-widget-wrap elementor-element-populated">
                              <div className="elementor-element elementor-element-b776e9a elementor-widget elementor-widget-heading" data-id="b776e9a" data-element_type="widget" data-widget_type="heading.default">
                                 <div className="elementor-widget-container">
                                    <h2 className="elementor-heading-title elementor-size-default">Чьи ПДн обрабатываются в ИС</h2>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </section>
                  <section className="elementor-section elementor-top-section elementor-element elementor-element-d4987c6 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="d4987c6" data-element_type="section">
                     <div className="elementor-container elementor-column-gap-default">
                        <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-d28f52c" data-id="d28f52c" data-element_type="column">
                           <div className="elementor-widget-wrap elementor-element-populated">
                              <div className="elementor-element elementor-element-6f16a2d elementor-widget elementor-widget-text-editor" data-id="6f16a2d" data-element_type="widget" data-widget_type="text-editor.default">
                                 <div className="elementor-widget-container">
                                    <p>К данным сотрудников требования по защищенности менее строгие, потому что сотрудники руководствуются локальными нормативными актами вашей организации.</p>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </section>
                  <section className="elementor-section elementor-top-section elementor-element elementor-element-015d79e elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="015d79e" data-element_type="section">
                     <div className="elementor-container elementor-column-gap-default">
                        <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-3e6a82a" data-id="3e6a82a" data-element_type="column">
                           <div className="elementor-widget-wrap elementor-element-populated">
                              <section className="elementor-section elementor-inner-section elementor-element elementor-element-3b69ec0 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="3b69ec0" data-element_type="section">
                                 <div className="elementor-container elementor-column-gap-default">
                                    <div className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-1a36f03" data-id="1a36f03" data-element_type="column">
                                       <div className="elementor-widget-wrap elementor-element-populated">
                                          <div className="elementor-element elementor-element-f5aaa1a elementor-widget elementor-widget-text-editor" data-id="f5aaa1a" data-element_type="widget" data-widget_type="text-editor.default">
                                             <div className="elementor-widget-container">
                                                <h3>Не сотрудников</h3>
                                                <Switch id="question_2_1" checked={(this.state.question[2]==1)} onChange={this.onChange} />
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-cd825a4" data-id="cd825a4" data-element_type="column">
                                       <div className="elementor-widget-wrap elementor-element-populated">
                                          <div className="elementor-element elementor-element-aaea0f2 elementor-widget elementor-widget-text-editor" data-id="aaea0f2" data-element_type="widget" data-widget_type="text-editor.default">
                                             <div className="elementor-widget-container">
                                                <p>ИС является информационной системой, обрабатывающей персональные данные субъектов персональных данных, не являющихся сотрудниками оператора.</p>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-e432365" data-id="e432365" data-element_type="column">
                                       <div className="elementor-widget-wrap elementor-element-populated">
                                          <div className="elementor-element elementor-element-ba5f5c6 elementor-widget elementor-widget-text-editor" data-id="ba5f5c6" data-element_type="widget" data-widget_type="text-editor.default">
                                             <div className="elementor-widget-container">
                                                <p><em>Все, что не бухгалтерская и не кадровая ИС.</em></p>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </section>
                           </div>
                        </div>
                     </div>
                  </section>
                  <section className="elementor-section elementor-top-section elementor-element elementor-element-2a508e2 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="2a508e2" data-element_type="section">
                     <div className="elementor-container elementor-column-gap-default">
                        <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-f4b60f5" data-id="f4b60f5" data-element_type="column">
                           <div className="elementor-widget-wrap elementor-element-populated">
                              <section className="elementor-section elementor-inner-section elementor-element elementor-element-8e79a0a elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="8e79a0a" data-element_type="section">
                                 <div className="elementor-container elementor-column-gap-default">
                                    <div className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-831f05f" data-id="831f05f" data-element_type="column">
                                       <div className="elementor-widget-wrap elementor-element-populated">
                                          <div className="elementor-element elementor-element-7cfdeae elementor-widget elementor-widget-text-editor" data-id="7cfdeae" data-element_type="widget" data-widget_type="text-editor.default">
                                             <div className="elementor-widget-container">
                                                <h3>Сотрудников</h3>
                                                <Switch id="question_2_2" checked={(this.state.question[2]==2)} onChange={this.onChange} />
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-c7590c7" data-id="c7590c7" data-element_type="column">
                                       <div className="elementor-widget-wrap elementor-element-populated">
                                          <div className="elementor-element elementor-element-2bc8932 elementor-widget elementor-widget-text-editor" data-id="2bc8932" data-element_type="widget" data-widget_type="text-editor.default">
                                             <div className="elementor-widget-container">
                                                <p>ИС является информационной системой, обрабатывающей персональные данные сотрудников оператора, если в ней обрабатываются персональные данные только указанных сотрудников.&nbsp;</p>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-9fcd756" data-id="9fcd756" data-element_type="column">
                                       <div className="elementor-widget-wrap elementor-element-populated">
                                          <div className="elementor-element elementor-element-43ae14f elementor-widget elementor-widget-text-editor" data-id="43ae14f" data-element_type="widget" data-widget_type="text-editor.default">
                                             <div className="elementor-widget-container">
                                                <p><em>Обычно это бухгалтерская или кадровая ИС.</em></p>
                                                <p><em>Они тоже обрабатывают персональные данные и их тоже нужно защищать.&nbsp;</em></p>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </section>
                           </div>
                        </div>
                     </div>
                  </section>
                  <section className="elementor-section elementor-top-section elementor-element elementor-element-2e8ff15 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="2e8ff15" data-element_type="section">
                     <div className="elementor-container elementor-column-gap-default">
                        <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-6e9bf0f" data-id="6e9bf0f" data-element_type="column">
                           <div className="elementor-widget-wrap elementor-element-populated">
                              <div className="elementor-element elementor-element-be7159f elementor-widget elementor-widget-heading" data-id="be7159f" data-element_type="widget" data-widget_type="heading.default">
                                 <div className="elementor-widget-container">
                                    <h2 className="elementor-heading-title elementor-size-default">Сколько субъектов ПДн в ИС?</h2>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </section>
                  <section className="elementor-section elementor-top-section elementor-element elementor-element-d4987c6 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="d4987c6" data-element_type="section">
                     <div className="elementor-container elementor-column-gap-default">
                        <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-d28f52c" data-id="d28f52c" data-element_type="column">
                           <div className="elementor-widget-wrap elementor-element-populated">
                              <div className="elementor-element elementor-element-6f16a2d elementor-widget elementor-widget-text-editor" data-id="6f16a2d" data-element_type="widget" data-widget_type="text-editor.default">
                                 <div className="elementor-widget-container">
                                    <p>Чем меньше вы обрабатываете субъектов ПДн, там меньше у вас будет уровень защищенности.</p>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </section>

                  <section className="elementor-section elementor-top-section elementor-element elementor-element-ad417cf elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="ad417cf" data-element_type="section">
                     <div className="elementor-container elementor-column-gap-default">
                        <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-97196d9" data-id="97196d9" data-element_type="column">
                           <div className="elementor-widget-wrap elementor-element-populated">
                              <section className="elementor-section elementor-inner-section elementor-element elementor-element-af1a8d2 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="af1a8d2" data-element_type="section">
                                 <div className="elementor-container elementor-column-gap-default">
                                    <div className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-1dd7ee4" data-id="1dd7ee4" data-element_type="column">
                                       <div className="elementor-widget-wrap elementor-element-populated">
                                          <div className="elementor-element elementor-element-8340146 elementor-widget elementor-widget-text-editor" data-id="8340146" data-element_type="widget" data-widget_type="text-editor.default">
                                             <div className="elementor-widget-container">
                                                <h3>&gt; 100 000</h3>
                                                <Switch id="question_3_1" checked={(this.state.question[3]==1)} onChange={this.onChange} />
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-551edb9" data-id="551edb9" data-element_type="column">
                                       <div className="elementor-widget-wrap elementor-element-populated">
                                          <div className="elementor-element elementor-element-9031cc8 elementor-widget elementor-widget-text-editor" data-id="9031cc8" data-element_type="widget" data-widget_type="text-editor.default">
                                             <div className="elementor-widget-container">
                                                <p>ИС обрабатывает персональные данные более чем 100 000 субъектов персональных данных</p>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-411c9cf" data-id="411c9cf" data-element_type="column">
                                       <div className="elementor-widget-wrap elementor-element-populated">
                                          <div className="elementor-element elementor-element-9c51fab elementor-widget elementor-widget-text-editor" data-id="9c51fab" data-element_type="widget" data-widget_type="text-editor.default">
                                             <div className="elementor-widget-container">
                                                <p><em>Из-за этого уровень защищенности может повыситься.</em></p>
                                                <p><em>Может ее разделить на две, чтобы каждая обрабатывала меньше 100 000 субъектов?</em></p>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </section>
                           </div>
                        </div>
                     </div>
                  </section>
                  <section className="elementor-section elementor-top-section elementor-element elementor-element-955f9df elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="955f9df" data-element_type="section">
                     <div className="elementor-container elementor-column-gap-default">
                        <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ce3cbfb" data-id="ce3cbfb" data-element_type="column">
                           <div className="elementor-widget-wrap elementor-element-populated">
                              <section className="elementor-section elementor-inner-section elementor-element elementor-element-8ad851a elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="8ad851a" data-element_type="section">
                                 <div className="elementor-container elementor-column-gap-default">
                                    <div className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-a09c25c" data-id="a09c25c" data-element_type="column">
                                       <div className="elementor-widget-wrap elementor-element-populated">
                                          <div className="elementor-element elementor-element-07794a5 elementor-widget elementor-widget-text-editor" data-id="07794a5" data-element_type="widget" data-widget_type="text-editor.default">
                                             <div className="elementor-widget-container">
                                                <h3>&lt; 100 000</h3>
                                                <Switch id="question_3_2" checked={(this.state.question[3]==2)} onChange={this.onChange} />
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-53ea8fe" data-id="53ea8fe" data-element_type="column">
                                       <div className="elementor-widget-wrap elementor-element-populated">
                                          <div className="elementor-element elementor-element-e9b59d7 elementor-widget elementor-widget-text-editor" data-id="e9b59d7" data-element_type="widget" data-widget_type="text-editor.default">
                                             <div className="elementor-widget-container">
                                                <p>ИС обрабатывает персональные данные менее чем 100 000 субъектов персональных данных</p>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-03ce9fb" data-id="03ce9fb" data-element_type="column">
                                       <div className="elementor-widget-wrap elementor-element-populated">
                                          <div className="elementor-element elementor-element-4384c08 elementor-widget elementor-widget-text-editor" data-id="4384c08" data-element_type="widget" data-widget_type="text-editor.default">
                                             <div className="elementor-widget-container">
                                                <p><em>Наиболее вероятно</em></p>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </section>
                           </div>
                        </div>
                     </div>
                  </section>
                  <section className="elementor-section elementor-top-section elementor-element elementor-element-1453ba5 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="1453ba5" data-element_type="section">
                     <div className="elementor-container elementor-column-gap-default">
                        <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-d8d7423" data-id="d8d7423" data-element_type="column">
                           <div className="elementor-widget-wrap elementor-element-populated">
                              <div className="elementor-element elementor-element-f06dd27 elementor-widget elementor-widget-heading" data-id="f06dd27" data-element_type="widget" data-widget_type="heading.default">
                                 <div className="elementor-widget-container">
                                    <h2 className="elementor-heading-title elementor-size-default">Актуальные угрозы для информационной системы</h2>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </section>
                  <section className="elementor-section elementor-top-section elementor-element elementor-element-d4987c6 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="d4987c6" data-element_type="section">
                     <div className="elementor-container elementor-column-gap-default">
                        <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-d28f52c" data-id="d28f52c" data-element_type="column">
                           <div className="elementor-widget-wrap elementor-element-populated">
                              <div className="elementor-element elementor-element-6f16a2d elementor-widget elementor-widget-text-editor" data-id="6f16a2d" data-element_type="widget" data-widget_type="text-editor.default">
                                 <div className="elementor-widget-container">
                                    <p>Чем ниже вы по списку категорий угроз, там меньше у вас будет уровень защищенности.</p>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </section>

                  <section className="elementor-section elementor-top-section elementor-element elementor-element-abee96e elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="abee96e" data-element_type="section">
                     <div className="elementor-container elementor-column-gap-default">
                        <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ecf2f39" data-id="ecf2f39" data-element_type="column">
                           <div className="elementor-widget-wrap elementor-element-populated">
                              <section className="elementor-section elementor-inner-section elementor-element elementor-element-c96b991 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="c96b991" data-element_type="section">
                                 <div className="elementor-container elementor-column-gap-default">
                                    <div className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-9b7f617" data-id="9b7f617" data-element_type="column">
                                       <div className="elementor-widget-wrap elementor-element-populated">
                                          <div className="elementor-element elementor-element-5b4bb81 elementor-widget elementor-widget-text-editor" data-id="5b4bb81" data-element_type="widget" data-widget_type="text-editor.default">
                                             <div className="elementor-widget-container">
                                                <h3>Первого типа</h3>
                                                <Switch id="question_4_1" checked={(this.state.question[4]==1)} onChange={this.onChange} />
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-e21f491" data-id="e21f491" data-element_type="column">
                                       <div className="elementor-widget-wrap elementor-element-populated">
                                          <div className="elementor-element elementor-element-ee9410a elementor-widget elementor-widget-text-editor" data-id="ee9410a" data-element_type="widget" data-widget_type="text-editor.default">
                                             <div className="elementor-widget-container">
                                                <p>Угрозы первого типа актуальны для информационной системы, если для нее в том числе актуальны угрозы, связанные с наличием недокументированных (недекларированных) возможностей в системном программном обеспечении, используемом в ИСПДн.</p>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-3ae8fe7" data-id="3ae8fe7" data-element_type="column">
                                       <div className="elementor-widget-wrap elementor-element-populated">
                                          <div className="elementor-element elementor-element-b93dd66 elementor-widget elementor-widget-text-editor" data-id="b93dd66" data-element_type="widget" data-widget_type="text-editor.default">
                                             <div className="elementor-widget-container">
                                                <p><em>Используйте сертифицированную операционную систему, это не сложно и не так дорого как кажется.</em></p>
                                                <p><em>Постарайтесь обосновать, почему для вас угрозы 1-ого типа неактуальны.</em></p>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </section>
                              <section className="elementor-section elementor-inner-section elementor-element elementor-element-382a859 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="382a859" data-element_type="section">
                                 <div className="elementor-container elementor-column-gap-default">
                                    <div className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-012567c" data-id="012567c" data-element_type="column">
                                       <div className="elementor-widget-wrap elementor-element-populated">
                                          <div className="elementor-element elementor-element-473756f elementor-widget elementor-widget-text-editor" data-id="473756f" data-element_type="widget" data-widget_type="text-editor.default">
                                             <div className="elementor-widget-container">
                                                <h3>Второго типа</h3>
                                                <Switch id="question_4_2" checked={(this.state.question[4]==2)} onChange={this.onChange} />

                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-c1d3210" data-id="c1d3210" data-element_type="column">
                                       <div className="elementor-widget-wrap elementor-element-populated">
                                          <div className="elementor-element elementor-element-79a6a7f elementor-widget elementor-widget-text-editor" data-id="79a6a7f" data-element_type="widget" data-widget_type="text-editor.default">
                                             <div className="elementor-widget-container">
                                                <p>Угрозы вторго типа актуальны для информационной системы, если для нее в том числе актуальны угрозы, связанные с наличием недокументированных (недекларированных) возможностей в прикладном программном обеспечении, используемом в ИСПДн.</p>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-52851e4" data-id="52851e4" data-element_type="column">
                                       <div className="elementor-widget-wrap elementor-element-populated">
                                          <div className="elementor-element elementor-element-3e21a69 elementor-widget elementor-widget-text-editor" data-id="3e21a69" data-element_type="widget" data-widget_type="text-editor.default">
                                             <div className="elementor-widget-container">
                                                <p><em>Найти сертифицированную информационную систему или сертифицировать свою&nbsp; сложно и очень дорого.</em></p>
                                                <p><em>Постарайтесь обосновать, почему для вас угрозы 2-ого типа неактуальны.</em></p>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </section>
                              <section className="elementor-section elementor-inner-section elementor-element elementor-element-8c8b01b elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="8c8b01b" data-element_type="section">
                                 <div className="elementor-container elementor-column-gap-default">
                                    <div className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-a7efce0" data-id="a7efce0" data-element_type="column">
                                       <div className="elementor-widget-wrap elementor-element-populated">
                                          <div className="elementor-element elementor-element-99bef96 elementor-widget elementor-widget-text-editor" data-id="99bef96" data-element_type="widget" data-widget_type="text-editor.default">
                                             <div className="elementor-widget-container">
                                                <h3>Третьего типа</h3>
                                                <Switch id="question_4_3" checked={(this.state.question[4]==3)} onChange={this.onChange} />

                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-f236c99" data-id="f236c99" data-element_type="column">
                                       <div className="elementor-widget-wrap elementor-element-populated">
                                          <div className="elementor-element elementor-element-97f89ff elementor-widget elementor-widget-text-editor" data-id="97f89ff" data-element_type="widget" data-widget_type="text-editor.default">
                                             <div className="elementor-widget-container">
                                                <p>Угрозы третьего типа актуальны для информационной системы, если для нее актуальны угрозы, не связанные с наличием недокументированных (недекларированных) возможностей в системном и прикладном программном обеспечении, используемом в ИСПДн.</p>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-186af5e" data-id="186af5e" data-element_type="column">
                                       <div className="elementor-widget-wrap elementor-element-populated">
                                          <div className="elementor-element elementor-element-40fa9f0 elementor-widget elementor-widget-text-editor" data-id="40fa9f0" data-element_type="widget" data-widget_type="text-editor.default">
                                             <div className="elementor-widget-container">
                                                <p><em>У вас угрозы третьего типа, если вам удалось избежать угроз первого и второго.</em></p>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </section>
                           </div>
                        </div>
                     </div>
                  </section>
                  <section className="elementor-section elementor-top-section elementor-element elementor-element-9ccc1c1 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="9ccc1c1" data-element_type="section">
                     <div className="elementor-container elementor-column-gap-default">
                        <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-74fb0f8" data-id="74fb0f8" data-element_type="column">
                           <div className="elementor-widget-wrap elementor-element-populated">
                              <div className="elementor-element elementor-element-fe24e42 elementor-widget elementor-widget-heading" data-id="fe24e42" data-element_type="widget" data-widget_type="heading.default">
                                 <div className="elementor-widget-container">
                                    <h2 className="elementor-heading-title elementor-size-default">Уровень защищенности информационной системы</h2>
                                 </div>
                              </div>
                              <div className="elementor-element elementor-element-eee7030 elementor-widget elementor-widget-text-editor" data-id="eee7030" data-element_type="widget" data-widget_type="text-editor.default">
                                 <div className="elementor-widget-container">
                                    <p>Если вы на предыдущих этапах правильно отметили критерии, значит у вас:&nbsp;</p>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </section>
                  <SecurityChoiceLevel
                    security_choice_level={this.state.answer}
                  />

                  <section className="elementor-section elementor-top-section elementor-element elementor-element-17e1846 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="17e1846" data-element_type="section">
                    <div className="elementor-container elementor-column-gap-default">
                      <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-9afa6a4" data-id="9afa6a4" data-element_type="column">
                          <div className="elementor-widget-wrap elementor-element-populated">

                            <div className="elementor-element elementor-element-37c1af4 elementor-widget elementor-widget-text-editor" data-id="37c1af4" data-element_type="widget" data-widget_type="text-editor.default">
                                <div className="elementor-widget-container">
                                  <p>Теперь, когда определен уровень защищенности, я помогу сформировать для вас документ <b>«Акт определения уровня защищенности ПДн»</b> в составе пакета документов:</p>
                                     <Module_FilesDownload                                     
                                       urlPrefix={this.props.urlPrefix}
                                       PacketID="act1"       
                                       name="Акт определения уровня защищенности ПДн"                                
                                     />
                                     <br/>
                                     <h3 className="elementor-heading-title elementor-size-default">Что дальше?</h3> 
                                     <br/>
                                     <p>Для того чтобы ещё подробнее разобраться с персональными данными и обеспечением их информационной безопасности, читайте также страницы нашего сайта:</p>
                                    <ul>
                                       <li>что такое <a href="/personal-data/">персональные данные</a>;</li>
                                       <li>как сделать <a href="/personal-data#agreement">согласие на оработку персональных данных</a>;</li>
                                       <li>как сделать <a href="/personal-data#assignment">поручение на обработку персональных данных</a>;</li>
                                       <li>как подготовить <a href="/personal-data#documents">пакет документов по персональным данным</a> и выпустить приказ по Организации;</li>
                                       <li>как подать <a href="/personal-data#notification"> уведомление в Роскомнадзор</a> о начале обработки персональных данных.</li>
                                    </ul>                                                                                                           

                                </div>

                            </div>
                                                    

                                  <br/>
                          </div>
                      </div>
                    </div>
                    </section>                  

               </div>
            </div>
            <div id="av-secondary-content" className="av-column-4">
               <section className="sidebar">
               </section>
            </div>
         </div>
      </div>
   </section>
</div>
</div>
    );
  }
};

 

export default Main;
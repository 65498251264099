import React from 'react';
import { NavLink } from 'react-router-dom';
import { Link, animateScroll as scroll } from "react-scroll";

   
      class Header extends React.Component {
  constructor(props) {
    super(props);
    //this.
    this.url=undefined;
    this.state = {
      redirect: 1,
      menu:null,
      selectedMenu:'/',
    };
  }

  render() {

    return (
<div/>


    );
  }
}
export default Header;

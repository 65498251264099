import React from 'react';
import { Tooltip, Spin, Drawer,Button,Modal, Form, Input, Select } from 'antd';
import { getData,postData,getUserID,downloadFile } from './getData';
import {ConstPersonalData} from './Const_Personal_Data.js';
import {  IssuesCloseOutlined  }  from '@ant-design/icons';

const ShowModal = (props) => {
   //console.log(props);
   const { Option } = Select;
   const layout = {
     labelCol: {
       span: 8,
     },
     wrapperCol: {
       span: 16,
     },
   };
   const tailLayout = {
     wrapperCol: {
       offset: 8,
       span: 16,
     },
   };   
   const [form] = Form.useForm();
   props.handleForm(form);
   const onFinish = (values) => {
     //console.log(values);
     props.handleOk(values);
   };
   const onChange = (e) => {
      const target = e.target;
      const value = target.type === 'text' ?  target.value:'';
      let inn = value.match(/[\d]+/i);    
      let inn_=(inn)?inn[0]:'';
      inn=inn_;   
      //console.log(inn);
      if ((inn.length==10)||(inn.length==12))
      {
         props.handleCheckINN(inn);
        //getData(this.props.urlPrefix+"/client/"+this.ClientID+"?inn="+inn+"&end",this.getINNCheck);
      }      
      //props.handleOk(values);
    };
 
   /*
   const onReset = () => {
     form.resetFields();
   };
 
   const onFill = () => {
     form.setFieldsValue({
       note: 'Hello world!',
       gender: 'male',
     });
   };
   */   
   //{props.handleOk}
   
   return(
<Modal
      title={props.title}
      visible={props.visible}
      //onOk={onFinish}
      confirmLoading={props.confirmLoading}
      onCancel={props.handleCancel}
      footer={[]}
      >      
    <p>{props.modalText}</p>     
    <p>Вы покупаете пакет всех документов портала в редактируемом виде в формате Word.<br/>
    Стоимость пакета документа 3 000 рублей, это чуть меньше среднего чека вашего похода в магазин.<br/> 
    Для покупки вы будете перенаправлены на сайт Яндекс.Касса</p>
      <Form //{...layout} 
      layout="vertical"

      form={form} name="control-hooks" onFinish={onFinish} >

      <Form.Item
        name="email"
        label="Укажите электронную почту, куда вам прислать чек (мы не будем присылать спам):"
        defaultValue="1"
        rules={[
          {
            required: true, message: 'Введите электронную почту' 
          },
        ]}
      >
        <Input placeholder="email@mail.ru"/>
      </Form.Item>
      <Form.Item
      // {...tailLayout}
      >
        <Button type="primary" htmlType="submit">
          Подтвердить
        </Button>
      </Form.Item>
    </Form>  
</Modal>
);
}

 
class Module_FastDoc extends React.Component {
  constructor(props) {
    super(props);
    this.ClientID;
    this.state = {
      /*
      question:[],
      answer:0,
      loading:true,
      */
      visible:false,
      confirmLoading:false,
      modalText:'',
      
      
   }
    this.handleFormVar=null;

    //this.onChange = this.onChange.bind(this);
    this.onClick = this.onClick.bind(this);
    this.getFileDownload = this.getFileDownload.bind(this);    
    //this.getResultSecLevel = this.getResultSecLevel.bind(this);  

    this.setVisible = this.setVisible.bind(this);
    this.setConfirmLoading = this.setConfirmLoading.bind(this);
    this.setModalText = this.setModalText.bind(this);
    this.showModal = this.showModal.bind(this);
    this.handleOk = this.handleOk.bind(this);
    this.handleCancel = this.handleCancel.bind(this);

    //this.getResultSecLevel = this.getResultSecLevel.bind(this);
    this.handleForm = this.handleForm.bind(this);
    this.getResultShowModal = this.getResultShowModal.bind(this);

    this.handleCheckINN = this.handleCheckINN.bind(this);
    this.getResultINN = this.getResultINN.bind(this);
    this.afterPost = this.afterPost.bind(this);

  }
  getResultINN(data)
  {
   if (data.status=='error')
   {
      console.log('Ошибка ИНН');
   }
   else
   {
      //console.log(data);
      this.handleFormVar.setFieldsValue({
         inn: data.data.inn,
         company_name_short: data.data.name,
       });
   
   };
  };
  handleCheckINN(inn)
  {
   
   getData(this.props.urlPrefix+"/client/?inn="+inn,this.getResultINN);
   //this.setState({ visible:visible });
  }
  setVisible(visible)
  {
   this.setState({ visible:visible });
  }
  setConfirmLoading(confirmLoading)
  {
   this.setState({ confirmLoading:confirmLoading });
  }
  setModalText(modalText)
  {
   this.setState({ modalText:modalText });
  }
  getResultShowModal(data)
  {
     //console.log(data);
     //console.log(this);
     
     this.handleFormVar.setFieldsValue({
      email: data.data.email,
    });
    

  }
  showModal () {
   this.setVisible(true);
   getData(this.props.urlPrefix+"/fastdoc/"+this.ClientID,this.getResultShowModal);
   /*
   form.setFieldsValue({
      note: 'Hello world!',
      gender: 'male',
    });
    */

  };
  handleForm (data) {
   //this.setState({ handleForm:data });
     //console.log(data);
     this.handleFormVar=data;
  }

  afterPost (data) {
   //this.setState({ handleForm:data });
     //console.log(data.data.confirmation_url);
     open(data.data.confirmation_url,'_self');
     /*
     this.setVisible(false);
     this.setConfirmLoading(false);
     this.onClick(null);
     */

     //this.handleFormVar=data;
  }

  handleOk (data) {
     //console.log(data);
     // 
   this.setModalText('Загружаю данные...');
   //getData(this.props.urlPrefix+"/seclevel/"+this.ClientID,this.getResultSecLevel);
   this.setConfirmLoading(true);

   var sum_data={};
   sum_data['email']=data.email;
   /*
   sum_data['amount_value']='3000';
   sum_data['amount_currency']='RUB';
   sum_data['description']='Пакет локальных нормативных актов по персональным данным за '+sum_data['amount_value']+' рублей';
   */
   
   //sum_data['url_callback']=document.location.href;
   sum_data['url_callback']=document.location.origin+'/orders';

   //console.log(document.location.origin);

   sum_data['packet_type']='1';
   sum_data['ClientID']=this.ClientID;
   postData(this.props.urlPrefix+"/order",'',this.afterPost,sum_data);
   /*
    setTimeout(() => {
      this.setVisible(false);
      this.setConfirmLoading(false);
      this.onClick(null);
    }, 2000);
   */    
  };

  handleCancel () {
    //console.log('Clicked cancel button');
    this.setVisible(false);
  };

  componentDidMount() {
    if (!this.ClientID) this.ClientID=getUserID(); 

    /*
   let question=[];
   question[1]=0;
   question[2]=0;
   question[3]=0;
   question[4]=0;

   this.setState({ question: question });
   getData(this.props.urlPrefix+"/seclevel/"+this.ClientID,this.getResultSecLevel);
   this.setState({ loading:true });
   */
  }
  getFileDownload(data)
  {
    //console.log(data);
    
    
    if (data.status=="success")
    {
      //window.open(this.props.urlPrefix+"/makedoc/act1?file_name="+data.data.file_name, '_blank');
      //downloadFile(data.data.url, "Акт определения уровня защищенности.zip");
      downloadFile(data.data.url, "Пакет локальных нормативных актов по персональным данным.zip");
    } else
    {
      
    }
    

  }
  onClick(e) {
    
    
    var sum_data={};
    sum_data['ClientID']=this.ClientID;
    //postData(this.props.urlPrefix+"/makedoc/act1/",this.ClientID,this.getFileDownload,sum_data);
    postData(this.props.urlPrefix+"/makedoc/is1/",this.ClientID,this.getFileDownload,sum_data);
    /*
    if (!this.ClientID) this.ClientID=getUserID(); 
   let question=[];
   question[1]=0;
   question[2]=0;
   question[3]=0;
   question[4]=0;

   this.setState({ question: question });
   getData(this.props.urlPrefix+"/seclevel/"+this.ClientID,this.getResultSecLevel);
   this.setState({ loading:true });
   */
  }
  
/*  
  getResultSecLevel(data){
    console.log(data);
    let question=[];
    question[1]=data.data.ispdn_type;
    question[2]=data.data.subject_pdn_type;
    question[3]=data.data.subject_pdn_count;
    question[4]=data.data.actual_threats_count;
 
    this.setState({ answer: data.data.security_choice_level,question: question,loading:false });
    
      
  };
  */

  /*
onChange(checked,e){
   //console.log(e.target);
   const target=e.target;
   //const id = target.id;
   let index=target.id.indexOf('_');
   let id=target.id.slice(index+1);
       //index=id.lastIndexOf('_');
   let idFirst=id.charAt(0);
   let idSecond=id.charAt(2);
   if (id!='')
   {
      //this.setState({ question1: id });
      let question=this.state.question;
      question[idFirst]=parseInt(idSecond);
      this.setState({ question: question });

      var sum_data={};
      sum_data['ispdn_type']=question[1];
      sum_data['subject_pdn_type']=question[2];
      sum_data['subject_pdn_count']=question[3];
      sum_data['actual_threats_count']=question[4];
      //console.log(this.ClientID);
      postData(this.props.urlPrefix+"/seclevel/",this.ClientID,this.getResultSecLevel,sum_data);
      this.setState({ loading:true });
   }
   //console.log(id);
   //console.log(index);
   //console.log(idFirst);
   //console.log(idSecond);
 };*/
  //this.setReState.bind(this);  
  render() {
   //console.log(this.state);
   if ((!this.props.buttons)||(!this.props.buttons.buy)||(this.props.buttons.buy.visible==false))
     return(<div className="button_invisible"/>);
   return (
  
<div className="button_invisible">
  <Tooltip title="Купить все документы портала в формате Word">
    <Button className="button_blue" icon={<IssuesCloseOutlined />} type="primary" shape="round" size={this.props.size} onClick={this.showModal} >
          Купить Word
    </Button>
  </Tooltip>

<ShowModal
      title="Покупка пакета документов"
      visible={this.state.visible}
      handleOk={this.handleOk}
      handleForm={this.handleForm}
      confirmLoading={this.state.confirmLoading}
      handleCancel={this.handleCancel}
      modalText={this.state.modalText}
      handleCheckINN={this.handleCheckINN}
      />

</div>
    );
  }
};

export default Module_FastDoc;
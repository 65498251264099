import React from 'react';
import { Switch, Spin, Drawer } from 'antd';
import { isStateMemberExpression } from 'eslint-plugin-react/lib/util/componentUtil';
import { getData,postData,getUserID,downloadFile } from './getData';
import {ConstPersonalData} from './Const_Personal_Data.js';
import Module_FastDoc from './Module_FastDoc.js';
import Module_DemoDoc from './Module_DemoDoc.js';
// Всплывающее окно с запросом на покупку
import Module_PopupBuy from './Module_PopupBuy.js';

import Module_Yookassa from './Module_Yookassa_old.js';
import { NavLink } from 'react-router-dom';
import Header_Menu from './Header_Menu.js';
import Module_FilesDownload from './Module_FilesDownload.js';
var url="";
const GenerateButton = (props) => {
   
   if (!props.userInfo) 
   //return(<div/>);
   return(<Spin tip="Загрузка данных..."/>);
   //console.log(props.userInfo.packet_type_1);
   if (props.userInfo.packet_type_1>=1) 
   //return((props.loading)?<Spin tip="Загрузка данных..."/>:<div style={{padding: "24px"}}/>);
   return(
      <div>
         <p>Введите свой ИНН и мы поможем вам сделать первоначальное заполнение шаблонов документов.</p>
         
         <Module_FastDoc
            urlPrefix={props.urlPrefix}
         />              
      </div>           
   )
   else
   return(
      <div>
         <p>Чтобы посмотреть, какие документы вы обязаны оформить по закону, скачайте демонстрационный пример в формате pdf.</p>
         <Module_DemoDoc
            urlPrefix={props.urlPrefix}
         />              
         <p>Купите пакет документов в редактируемом виде в формате word и мы поможем вам сделать первоначальное заполнение шаблонов документов.</p>
         <Module_PopupBuy
            urlPrefix={props.urlPrefix}
         />   
      </div>           
   );
 }
 
class Main extends React.Component {
  constructor(props) {
    super(props);
    this.ClientID;
    this.state = {
   }
    //this.onClick = this.onClick.bind(this);
    //this.getFileDownload = this.getFileDownload.bind(this);    
  }
  componentDidMount() {
    if (!this.ClientID) this.ClientID=getUserID(); 
      this.setState({ loading:true });
  }
  /*
  onClick(e) {   
    var sum_data={};
    sum_data['ClientID']=this.ClientID;
    //postData(this.props.urlPrefix+"/makedoc/act1/",this.ClientID,this.getFileDownload,sum_data);
  }
  */
  
  //this.setReState.bind(this);  
  render() {
   //console.log(this.props);
   url=this.props.urlPrefix;
     
    return (
<div>
<section id="breadcrumb-section" className="breadcrumb-area breadcrumb-left действующий эффект хлебных крошек">
	<div className="av-container">
		<div className="av-columns-area">
			<div className="av-column-12">
				<div className="breadcrumb-content">
					<div className="breadcrumb-heading wow fadeInLeft" style={{"visibility": "visible", "animationName": "fadeInLeft"}}>
						<h1>Карта сайта</h1>	
					</div>
               <div className="breadcrumb-list_" >
                  <Header_Menu/> 
                  <ol className="breadcrumb-list wow fadeInRight" style={{"visibility": "visible", "animationName": "fadeInRight"}}>
                     <li>
                        <a href="/">Информационная безопасность</a>
                     </li>
                  </ol>

               </div>

				</div>
			</div>
		</div>
	</div>
</section>


<div id="content" className="gradiant-content">
   <section id="post-section" className="post-section av-py-default">
      <div className="av-container">
         <div className="av-columns-area">
            <div id="av-primary-content" className="av-column-12">
               <div data-elementor-type="wp-page" data-elementor-id="108" className="elementor elementor-108">
                 
                  <section className="elementor-section elementor-top-section elementor-element elementor-element-d4987c6 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="d4987c6" data-element_type="section">
                     <div className="elementor-container elementor-column-gap-default">
                        <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-d28f52c" data-id="d28f52c" data-element_type="column">
                           <div className="elementor-widget-wrap elementor-element-populated">
                              <div className="elementor-element elementor-element-6f16a2d elementor-widget elementor-widget-text-editor" data-id="6f16a2d" data-element_type="widget" data-widget_type="text-editor.default">
                                 <div className="elementor-widget-container">
                                    <p>Для того чтобы подробнее разобраться с персональными данными и обеспечением их информационной безопасности, читайте страницы нашего сайта:</p>
                                    <ul>
                                       <li>что такое <a href="/personal-data/">персональные данные</a>;</li>
                                       <li>как определить<a href="/personal-data/security-level/"> уровень защищенности информационной системы персональных данных и сделать соответсвующий акт</a>;</li>
                                       <li>как сделать <a href="/personal-data#agreement">согласие на оработку персональных данных</a>;</li>
                                       <li>как сделать <a href="/personal-data#assignment">поручение на обработку персональных данных</a>;</li>
                                       <li>как подготовить <a href="/personal-data#documents">пакет документов по персональным данным</a> и выпустить приказ по Организации;</li>
                                       <li>как подать <a href="/personal-data#notification"> уведомление в Роскомнадзор</a> о начале обработки персональных данных.</li>
                                    </ul>                                                                                                           
                                    <Module_FilesDownload
                                       urlPrefix={url}
                                       PacketID="all"       
                                       name="Все документы портала"
                                    />
                                    
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </section>               

               </div>
            </div>
         </div>
      </div>
   </section>
</div>
</div>
    );
  }
};

export default Main;
/*
                                    <Module_Yookassa
                                       urlPrefix={this.props.urlPrefix}
                                    />         
*/
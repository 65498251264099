import { Spin } from 'antd';
const answer0=
<section className="elementor-section elementor-top-section elementor-element elementor-element-3023254 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="3023254" data-element_type="section">
<div className="elementor-container elementor-column-gap-default">
   <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-7de6d47" data-id="7de6d47" data-element_type="column">
      <div className="elementor-widget-wrap elementor-element-populated">
         <section className="elementor-section elementor-inner-section elementor-element elementor-element-a02a53b elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="a02a53b" data-element_type="section">
            <div className="elementor-container elementor-column-gap-default">
            <Spin tip="Загрузка...">
            </Spin>
            </div>
         </section>
      </div>
   </div>
</div>
</section>
;
const answer1=
<section className="elementor-section elementor-top-section elementor-element elementor-element-3023254 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="3023254" data-element_type="section">
<div className="elementor-container elementor-column-gap-default">
   <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-7de6d47" data-id="7de6d47" data-element_type="column">
      <div className="elementor-widget-wrap elementor-element-populated">
         <section className="elementor-section elementor-inner-section elementor-element elementor-element-a02a53b elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="a02a53b" data-element_type="section">
            <div className="elementor-container elementor-column-gap-default">
               <div className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-9ac6b91" data-id="9ac6b91" data-element_type="column">
                  <div className="elementor-widget-wrap elementor-element-populated">
                     <div className="elementor-element elementor-element-ef2dafa elementor-widget elementor-widget-text-editor" data-id="ef2dafa" data-element_type="widget" data-widget_type="text-editor.default">
                        <div className="elementor-widget-container">
                           <h3>1 уровень защищенности</h3>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-00ef56e" data-id="00ef56e" data-element_type="column">
                  <div className="elementor-widget-wrap elementor-element-populated">
                     <div className="elementor-element elementor-element-aefe2e8 elementor-widget elementor-widget-text-editor" data-id="aefe2e8" data-element_type="widget" data-widget_type="text-editor.default">
                        <div className="elementor-widget-container">
                           <p id="p_24" className="s_1">Необходимость обеспечения первого уровня защищенности персональных данных при их обработке в информационной системе устанавливается при наличии хотя бы одного из следующих условий:</p>
                           <div id="block_11" className="block">
                              <p id="p_25" className="s_1">а)&nbsp;для ИС актуальны угрозы первого типа и ИС обрабатывает либо специальные категории персональных данных, либо биометрические персональные данные, либо иные категории персональных данных;</p>
                           </div>
                           <div id="block_12" className="block">
                              <p id="p_26" className="s_1">б)&nbsp;для ИС актуальны угрозы второго типа и ИС обрабатывает специальные категории персональных данных более чем 100000&nbsp;субъектов персональных данных, не являющихся сотрудниками оператора.</p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </div>
   </div>
</div>
</section>
;
const answer2=
<section className="elementor-section elementor-top-section elementor-element elementor-element-67ed1b4 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="67ed1b4" data-element_type="section">
<div className="elementor-container elementor-column-gap-default">
   <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-4955661" data-id="4955661" data-element_type="column">
      <div className="elementor-widget-wrap elementor-element-populated">
         <section className="elementor-section elementor-inner-section elementor-element elementor-element-30a65a3 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="30a65a3" data-element_type="section">
            <div className="elementor-container elementor-column-gap-default">
               <div className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-7d42120" data-id="7d42120" data-element_type="column">
                  <div className="elementor-widget-wrap elementor-element-populated">
                     <div className="elementor-element elementor-element-7dacf86 elementor-widget elementor-widget-text-editor" data-id="7dacf86" data-element_type="widget" data-widget_type="text-editor.default">
                        <div className="elementor-widget-container">
                        <h3>2 уровень защищенности</h3>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-885bf11" data-id="885bf11" data-element_type="column">
                  <div className="elementor-widget-wrap elementor-element-populated">
                     <div className="elementor-element elementor-element-ee5a6a3 elementor-widget elementor-widget-text-editor" data-id="ee5a6a3" data-element_type="widget" data-widget_type="text-editor.default">
                        <div className="elementor-widget-container">
                           <p id="p_27" className="s_1">Необходимость обеспечения второго уровня защищенности персональных данных при их обработке в информационной системе устанавливается при наличии хотя бы одного из следующих условий:</p>
                           <div id="block_14" className="block">
                              <p id="p_28" className="s_1">а)&nbsp;для ИС актуальны угрозы первого&nbsp;типа и ИС обрабатывает общедоступные персональные данные;</p>
                           </div>
                           <div id="block_15" className="block">
                              <p id="p_29" className="s_1">б)&nbsp;для ИС актуальны угрозы второго&nbsp;типа и ИС обрабатывает специальные категории персональных данных сотрудников оператора или специальные категории персональных данных менее чем 100000&nbsp;субъектов персональных данных, не являющихся сотрудниками оператора;</p>
                           </div>
                           <div id="block_16" className="block">
                              <p id="p_30" className="s_1">в)&nbsp;для ИС актуальны угрозы второго&nbsp;типа и ИС обрабатывает биометрические персональные данные;</p>
                           </div>
                           <div id="block_17" className="block">
                              <p id="p_31" className="s_1">г)&nbsp;для ИС актуальны угрозы второго&nbsp;типа и ИС обрабатывает общедоступные персональные данные более чем 100000 субъектов персональных данных, не являющихся сотрудниками оператора;</p>
                           </div>
                           <div id="block_18" className="block">
                              <p id="p_32" className="s_1">д)&nbsp;для ИС актуальны угрозы второго&nbsp;типа и ИС обрабатывает иные категории персональных данных более чем 100000 субъектов персональных данных, не являющихся сотрудниками оператора;</p>
                           </div>
                           <div id="block_19" className="block">
                              <p id="p_33" className="s_1">е)&nbsp;для ИС актуальны угрозы третьего&nbsp;типа и ИС обрабатывает специальные категории персональных данных более чем 100000&nbsp;субъектов персональных данных, не являющихся сотрудниками оператора.</p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </div>
   </div>
</div>
</section>
;
const answer3=
<section className="elementor-section elementor-top-section elementor-element elementor-element-ebe9ac4 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="ebe9ac4" data-element_type="section">
<div className="elementor-container elementor-column-gap-default">
   <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b88b5ce" data-id="b88b5ce" data-element_type="column">
      <div className="elementor-widget-wrap elementor-element-populated">
         <section className="elementor-section elementor-inner-section elementor-element elementor-element-46e40a2 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="46e40a2" data-element_type="section">
            <div className="elementor-container elementor-column-gap-default">
               <div className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-aead74a" data-id="aead74a" data-element_type="column">
                  <div className="elementor-widget-wrap elementor-element-populated">
                     <div className="elementor-element elementor-element-7ff94b2 elementor-widget elementor-widget-text-editor" data-id="7ff94b2" data-element_type="widget" data-widget_type="text-editor.default">
                        <div className="elementor-widget-container">
                           <h3>3 уровень защищенности</h3>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-ea0cac7" data-id="ea0cac7" data-element_type="column">
                  <div className="elementor-widget-wrap elementor-element-populated">
                     <div className="elementor-element elementor-element-dd6d649 elementor-widget elementor-widget-text-editor" data-id="dd6d649" data-element_type="widget" data-widget_type="text-editor.default">
                        <div className="elementor-widget-container">
                           <p id="p_34" className="s_1">Необходимость обеспечения третьего уровня защищенности персональных данных при их обработке в информационной системе устанавливается при наличии хотя бы одного из следующих условий:</p>
                           <div id="block_21" className="block">
                              <p id="p_35" className="s_1">а)&nbsp;для ИС актуальны угрозы второго&nbsp;типа и ИС обрабатывает общедоступные персональные данные сотрудников оператора или общедоступные персональные данные менее чем 100000&nbsp;субъектов персональных данных, не являющихся сотрудниками оператора;</p>
                           </div>
                           <div id="block_22" className="block">
                              <p id="p_36" className="s_1">б)&nbsp;для ИС актуальны угрозы второго&nbsp;типа и ИС обрабатывает иные категории персональных данных сотрудников оператора или иные категории персональных данных менее чем 100000&nbsp;субъектов персональных данных, не являющихся сотрудниками оператора;</p>
                           </div>
                           <div id="block_23" className="block">
                              <p id="p_37" className="s_1">в)&nbsp;для ИС актуальны угрозы третьего&nbsp;типа и ИС обрабатывает специальные категории персональных данных сотрудников оператора или специальные категории персональных данных менее чем 100000&nbsp;субъектов персональных данных, не являющихся сотрудниками оператора;</p>
                           </div>
                           <div id="block_24" className="block">
                              <p id="p_38" className="s_1">г)&nbsp;для ИС актуальны угрозы третьего&nbsp;типа и ИС обрабатывает биометрические персональные данные;</p>
                           </div>
                           <div id="block_25" className="block">
                              <p id="p_39" className="s_1">д)&nbsp;для ИС актуальны угрозы третьего&nbsp;типа и ИС обрабатывает иные категории персональных данных более чем 100000 субъектов персональных данных, не являющихся сотрудниками оператора.</p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </div>
   </div>
</div>
</section>
;

const answer4=
<section className="elementor-section elementor-top-section elementor-element elementor-element-23484a2 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="23484a2" data-element_type="section">
<div className="elementor-container elementor-column-gap-default">
   <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-1e9c8fa" data-id="1e9c8fa" data-element_type="column">
      <div className="elementor-widget-wrap elementor-element-populated">
         <section className="elementor-section elementor-inner-section elementor-element elementor-element-389cc40 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="389cc40" data-element_type="section">
            <div className="elementor-container elementor-column-gap-default">
               <div className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-addd411" data-id="addd411" data-element_type="column">
                  <div className="elementor-widget-wrap elementor-element-populated">
                     <div className="elementor-element elementor-element-f68d84c elementor-widget elementor-widget-text-editor" data-id="f68d84c" data-element_type="widget" data-widget_type="text-editor.default">
                        <div className="elementor-widget-container">
                           <h3>4 уровень защищенности</h3>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-fd1adb4" data-id="fd1adb4" data-element_type="column">
                  <div className="elementor-widget-wrap elementor-element-populated">
                     <div className="elementor-element elementor-element-506366b elementor-widget elementor-widget-text-editor" data-id="506366b" data-element_type="widget" data-widget_type="text-editor.default">
                        <div className="elementor-widget-container">
                           <p id="p_40" className="s_1">Необходимость обеспечения 4-го уровня защищенности персональных данных при их обработке в информационной системе устанавливается при наличии хотя бы одного из следующих условий:</p>
                           <div id="block_27" className="block">
                              <p id="p_41" className="s_1">а)&nbsp;для ИС актуальны угрозы третьего&nbsp;типа и ИС обрабатывает общедоступные персональные данные;</p>
                           </div>
                           <div id="block_28" className="block">
                              <p id="p_42" className="s_1">б)&nbsp;для ИС актуальны угрозы третьего&nbsp;типа и ИС обрабатывает иные категории персональных данных сотрудников оператора или иные категории персональных данных менее чем 100000&nbsp;субъектов персональных данных, не являющихся сотрудниками оператора.</p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </div>
   </div>
</div>
</section>;

var ConstPersonalData = 
[ 
   {
      "id":"0",
      "value":answer0,
   },
   {
      "id":"1",
      "value":answer1,
   },
   {
      "id":"2",
      "value":answer2,
   },
   {
      "id":"3",
      "value":answer3,
   },
   {
      "id":"4",
      "value":answer4,
   },
]
   export {
      ConstPersonalData
   };   
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Link, animateScroll as scroll } from "react-scroll";

   
      class Header extends React.Component {
  constructor(props) {
    super(props);
    //this.
    this.url=undefined;
    this.state = {
      redirect: 1,
      menu:null,
      selectedMenu:'/',
    };
  }

  render() {

    return (
<header id="header-section" className="header header-seven">
	<div className="navigator-wrapper" style={{"minHeight":"287px"}}>
   <div className="theme-mobile-nav sticky-nav "> 
			<div className="av-container">
				<div className="av-columns-area">
					<div className="av-column-12">
						<div className="theme-mobile-menu">
							<div className="mobile-logo">
								<div className="logo">
									 <a href="http://is.mindstep.ru/" className="custom-logo-link" rel="home">
                               <img width="480" height="240" src="http://is.mindstep.ru/wp-content/uploads/2022/07/bender.png" className="custom-logo" alt="автоматизируй это!" title="Персональные данные"/>
                               </a>						<p className="site-description">автоматизируй это!</p>
										</div>
							</div>
							<div className="menu-toggle-wrap">
								<div className="mobile-menu-right"><ul className="header-wrap-right"></ul></div>
								<div className="hamburger hamburger-menu">
									<button type="button" className="toggle-lines menu-toggle">
										<div className="top-bun"></div>
										<div className="meat"></div>
										<div className="bottom-bun"></div>
									</button>
								</div>
							</div>
							<div id="mobile-m" className="mobile-menu">
								<button type="button" className="header-close-menu close-style"></button>
							<ul id="menu-menu_main" className="menu-wrap"><li itemScope="itemScope" itemType="https://www.schema.org/SiteNavigationElement" id="menu-item-137" className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-108 current_page_item menu-item-has-children dropdown active menu-item-137 nav-item"><a title="Персональные данные" href="http://is.mindstep.ru/consumer-data/" className="nav-link" aria-current="page">Персональные данные</a>
<span className="mobile-toggler d-av-none"><button type="button" className="fa fa-chevron-right" aria-label="Mobile Toggler" aria-hidden="true"></button></span><ul className="dropdown-menu" role="menu">
	<li itemScope="itemScope" itemType="https://www.schema.org/SiteNavigationElement" id="menu-item-158" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-158 nav-item"><a title="Cистема защиты персональных данных" href="http://is.mindstep.ru/consumer-data/protection_system/" className="dropdown-item">Cистема защиты персональных данных</a></li>
	<li itemScope="itemScope" itemType="https://www.schema.org/SiteNavigationElement" id="menu-item-159" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-159 nav-item"><a title="Защита персональных данных" href="http://is.mindstep.ru/consumer-data/ccpa/" className="dropdown-item">Защита персональных данных</a></li>
	<li itemScope="itemScope" itemType="https://www.schema.org/SiteNavigationElement" id="menu-item-160" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-160 nav-item"><a title="Модель нарушителя" href="http://is.mindstep.ru/consumer-data/intruder-model/" className="dropdown-item">Модель нарушителя</a></li>
	<li itemScope="itemScope" itemType="https://www.schema.org/SiteNavigationElement" id="menu-item-161" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-161 nav-item"><a title="Модель угроз" href="http://is.mindstep.ru/consumer-data/threat_model/" className="dropdown-item">Модель угроз</a></li>
	<li itemScope="itemScope" itemType="https://www.schema.org/SiteNavigationElement" id="menu-item-162" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-162 nav-item"><a title="Перечень персональных данных" href="http://is.mindstep.ru/consumer-data/list/" className="dropdown-item">Перечень персональных данных</a></li>
	<li itemScope="itemScope" itemType="https://www.schema.org/SiteNavigationElement" id="menu-item-163" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-163 nav-item"><a title="Политика обработки персональных данных" href="http://is.mindstep.ru/consumer-data/processing/" className="dropdown-item">Политика обработки персональных данных</a></li>
</ul>
</li>
<li itemScope="itemScope" itemType="https://www.schema.org/SiteNavigationElement" id="menu-item-140" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children dropdown menu-item-140 nav-item"><a title="Информационная безопасность" href="http://is.mindstep.ru/information-security/" className="nav-link">Информационная безопасность</a>
<span className="mobile-toggler d-av-none"><button type="button" className="fa fa-chevron-right" aria-label="Mobile Toggler" aria-hidden="true"></button></span><ul className="dropdown-menu" aria-labelledby="mobile-toggler d-av-none'><button type='button' class='fa fa-chevron-right' aria-label='Mobile Toggler'></button></span><ul className=" role="menu">
	<li itemScope="itemScope" itemType="https://www.schema.org/SiteNavigationElement" id="menu-item-141" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-141 nav-item"><a title="Обеспечение информационной безопасности" href="http://is.mindstep.ru/information-security/ensuring/" className="dropdown-item">Обеспечение информационной безопасности</a></li>
	<li itemScope="itemScope" itemType="https://www.schema.org/SiteNavigationElement" id="menu-item-164" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-164 nav-item"><a title="Аудит информационной безопасности" href="http://is.mindstep.ru/information-security/audit/" className="dropdown-item">Аудит информационной безопасности</a></li>
	<li itemScope="itemScope" itemType="https://www.schema.org/SiteNavigationElement" id="menu-item-165" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-165 nav-item"><a title="Концепция обеспечения безопасности" href="http://is.mindstep.ru/information-security/conception/" className="dropdown-item">Концепция обеспечения безопасности</a></li>
	<li itemScope="itemScope" itemType="https://www.schema.org/SiteNavigationElement" id="menu-item-166" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-166 nav-item"><a title="Матрица доступа" href="http://is.mindstep.ru/information-security/access-matrix/" className="dropdown-item">Матрица доступа</a></li>
	<li itemScope="itemScope" itemType="https://www.schema.org/SiteNavigationElement" id="menu-item-167" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-167 nav-item"><a title="Меры по информационной безопасности" href="http://is.mindstep.ru/information-security/measures/" className="dropdown-item">Меры по информационной безопасности</a></li>
	<li itemScope="itemScope" itemType="https://www.schema.org/SiteNavigationElement" id="menu-item-169" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-169 nav-item"><a title="Политика информационной безопасности" href="http://is.mindstep.ru/information-security/policy/" className="dropdown-item">Политика информационной безопасности</a></li>
	<li itemScope="itemScope" itemType="https://www.schema.org/SiteNavigationElement" id="menu-item-170" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-170 nav-item"><a title="Типовое положение информационной безопасности" href="http://is.mindstep.ru/information-security/regulations/" className="dropdown-item">Типовое положение информационной безопасности</a></li>
</ul>
</li>
<li itemScope="itemScope" itemType="https://www.schema.org/SiteNavigationElement" id="menu-item-138" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children dropdown menu-item-138 nav-item"><a title="Защита информации" href="http://is.mindstep.ru/information-technology-security/" className="nav-link">Защита информации</a>
<span className="mobile-toggler d-av-none"><button type="button" className="fa fa-chevron-right" aria-label="Mobile Toggler" aria-hidden="true"></button></span><ul className="dropdown-menu" aria-labelledby="mobile-toggler d-av-none'><button type='button' class='fa fa-chevron-right' aria-label='Mobile Toggler'></button></span><ul className=" role="menu">
	<li itemScope="itemScope" itemType="https://www.schema.org/SiteNavigationElement" id="menu-item-155" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-155 nav-item"><a title="Акты информационной безопасности" href="http://is.mindstep.ru/information-technology-security/acts/" className="dropdown-item">Акты информационной безопасности</a></li>
	<li itemScope="itemScope" itemType="https://www.schema.org/SiteNavigationElement" id="menu-item-156" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-156 nav-item"><a title="Инструкции администратора безопасности" href="http://is.mindstep.ru/information-technology-security/instructions/" className="dropdown-item">Инструкции администратора безопасности</a></li>
	<li itemScope="itemScope" itemType="https://www.schema.org/SiteNavigationElement" id="menu-item-157" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-157 nav-item"><a title="Методы защиты информации" href="http://is.mindstep.ru/information-technology-security/methods/" className="dropdown-item">Методы защиты информации</a></li>
</ul>
</li>
<li itemScope="itemScope" itemType="https://www.schema.org/SiteNavigationElement" id="menu-item-153" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-153 nav-item"><a title="Конфиденциальность" href="http://is.mindstep.ru/confidential/" className="nav-link">Конфиденциальность</a></li>
</ul></div>
							<div id="mob-h-top" className="mobi-head-top"></div>
						</div>
					</div>
				</div>
			</div>        
		</div>

<div className="nav-area d-none d-av-block">
   <div className="navbar-full">
      <div className="av-container">
         <div className="av-columns-area">
            <div className="av-column-4 my-auto">
               <div className="logo">
                  <a href="http://is.mindstep.ru/" className="custom-logo-link" rel="home">
                     <img width="480" height="240" src="http://is.mindstep.ru/wp-content/uploads/2022/07/bender.png" className="custom-logo" alt="автоматизируй это!" title="Персональные данные"/></a>						
                  <p className="site-description">автоматизируй это!</p>
               </div>
            </div>
            <div className="av-column-8 my-auto">
               <div className="theme-menu">
                  <div className="menu-right">
                     <ul className="header-wrap-right">
                        <li className="widget-wrap">
                           <div className="widget-wrp">
                              <aside className="widget widget-contact">
                                 <div className="contact-area ct-area1">
                                    <div className="contact-icon"><i className="fa fa-clock-o" aria-hidden="true"></i></div>
                                    <div className="contact-info"><span className="title">Work</span></div>
                                    <div className="contact-info"><a href="#" className="contact-info"><span className="text">Around the clock!</span></a></div>
                                 </div>
                              </aside>
                              <aside className="widget widget-contact">
                                 <div className="contact-area ct-area2">
                                    <div className="contact-icon"><i className="fa fa-telegram" aria-hidden="true"></i></div>
                                    <div className="contact-info"><span className="title">Telegram</span></div>
                                    <div className="contact-info"><a href="https://t.me/Pochemuka_Bot" target="_blank" className="contact-info"> <span className="text">Chat it!</span></a></div>
                                 </div>
                              </aside>
                              <aside className="widget widget-contact">
                                 <div className="contact-area ct-area3">
                                    <div className="contact-icon"><i className="fa fa-envelope" aria-hidden="true"></i></div>
                                    <div className="contact-info"><span className="title">Email</span></div>
                                    <div className="contact-info"><a href="#"><span className="text">Click it!</span></a></div>
                                 </div>
                              </aside>
                           </div>
                        </li>
                     </ul>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
<div className="navbar-area sticky-nav d-none d-av-block">
			<div className="av-container">
				<div className="av-columns-area">
					<div className="av-column-12 my-auto">
						<div className="theme-menu">
							<nav className="menubar">
								 <ul id="menu-menu_main" className="menu-wrap"><li itemScope="itemScope" itemType="https://www.schema.org/SiteNavigationElement" id="menu-item-137" className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-108 current_page_item menu-item-has-children dropdown active menu-item-137 nav-item"><a title="Персональные данные" href="http://is.mindstep.ru/consumer-data/" className="nav-link" aria-current="page">Персональные данные</a>
<span className="mobile-toggler d-av-none"><button type="button" className="fa fa-chevron-right" aria-label="Mobile Toggler" aria-hidden="true"></button></span><ul className="dropdown-menu" role="menu">
	<li itemScope="itemScope" itemType="https://www.schema.org/SiteNavigationElement" id="menu-item-158" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-158 nav-item"><a title="Cистема защиты персональных данных" href="http://is.mindstep.ru/consumer-data/protection_system/" className="dropdown-item">Cистема защиты персональных данных</a></li>
	<li itemScope="itemScope" itemType="https://www.schema.org/SiteNavigationElement" id="menu-item-159" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-159 nav-item"><a title="Защита персональных данных" href="http://is.mindstep.ru/consumer-data/ccpa/" className="dropdown-item">Защита персональных данных</a></li>
	<li itemScope="itemScope" itemType="https://www.schema.org/SiteNavigationElement" id="menu-item-160" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-160 nav-item"><a title="Модель нарушителя" href="http://is.mindstep.ru/consumer-data/intruder-model/" className="dropdown-item">Модель нарушителя</a></li>
	<li itemScope="itemScope" itemType="https://www.schema.org/SiteNavigationElement" id="menu-item-161" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-161 nav-item"><a title="Модель угроз" href="http://is.mindstep.ru/consumer-data/threat_model/" className="dropdown-item">Модель угроз</a></li>
	<li itemScope="itemScope" itemType="https://www.schema.org/SiteNavigationElement" id="menu-item-162" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-162 nav-item"><a title="Перечень персональных данных" href="http://is.mindstep.ru/consumer-data/list/" className="dropdown-item">Перечень персональных данных</a></li>
	<li itemScope="itemScope" itemType="https://www.schema.org/SiteNavigationElement" id="menu-item-163" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-163 nav-item"><a title="Политика обработки персональных данных" href="http://is.mindstep.ru/consumer-data/processing/" className="dropdown-item">Политика обработки персональных данных</a></li>
</ul>
</li>
<li itemScope="itemScope" itemType="https://www.schema.org/SiteNavigationElement" id="menu-item-140" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children dropdown menu-item-140 nav-item"><a title="Информационная безопасность" href="http://is.mindstep.ru/information-security/" className="nav-link">Информационная безопасность</a>
<span className="mobile-toggler d-av-none"><button type="button" className="fa fa-chevron-right" aria-label="Mobile Toggler" aria-hidden="true"></button></span><ul className="dropdown-menu" aria-labelledby="mobile-toggler d-av-none'><button type='button' class='fa fa-chevron-right' aria-label='Mobile Toggler'></button></span><ul className=" role="menu">
	<li itemScope="itemScope" itemType="https://www.schema.org/SiteNavigationElement" id="menu-item-141" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-141 nav-item"><a title="Обеспечение информационной безопасности" href="http://is.mindstep.ru/information-security/ensuring/" className="dropdown-item">Обеспечение информационной безопасности</a></li>
	<li itemScope="itemScope" itemType="https://www.schema.org/SiteNavigationElement" id="menu-item-164" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-164 nav-item"><a title="Аудит информационной безопасности" href="http://is.mindstep.ru/information-security/audit/" className="dropdown-item">Аудит информационной безопасности</a></li>
	<li itemScope="itemScope" itemType="https://www.schema.org/SiteNavigationElement" id="menu-item-165" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-165 nav-item"><a title="Концепция обеспечения безопасности" href="http://is.mindstep.ru/information-security/conception/" className="dropdown-item">Концепция обеспечения безопасности</a></li>
	<li itemScope="itemScope" itemType="https://www.schema.org/SiteNavigationElement" id="menu-item-166" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-166 nav-item"><a title="Матрица доступа" href="http://is.mindstep.ru/information-security/access-matrix/" className="dropdown-item">Матрица доступа</a></li>
	<li itemScope="itemScope" itemType="https://www.schema.org/SiteNavigationElement" id="menu-item-167" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-167 nav-item"><a title="Меры по информационной безопасности" href="http://is.mindstep.ru/information-security/measures/" className="dropdown-item">Меры по информационной безопасности</a></li>
	<li itemScope="itemScope" itemType="https://www.schema.org/SiteNavigationElement" id="menu-item-169" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-169 nav-item"><a title="Политика информационной безопасности" href="http://is.mindstep.ru/information-security/policy/" className="dropdown-item">Политика информационной безопасности</a></li>
	<li itemScope="itemScope" itemType="https://www.schema.org/SiteNavigationElement" id="menu-item-170" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-170 nav-item"><a title="Типовое положение информационной безопасности" href="http://is.mindstep.ru/information-security/regulations/" className="dropdown-item">Типовое положение информационной безопасности</a></li>
</ul>
</li>
<li itemScope="itemScope" itemType="https://www.schema.org/SiteNavigationElement" id="menu-item-138" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children dropdown menu-item-138 nav-item"><a title="Защита информации" href="http://is.mindstep.ru/information-technology-security/" className="nav-link">Защита информации</a>
<span className="mobile-toggler d-av-none"><button type="button" className="fa fa-chevron-right" aria-label="Mobile Toggler" aria-hidden="true"></button></span><ul className="dropdown-menu" aria-labelledby="mobile-toggler d-av-none'><button type='button' class='fa fa-chevron-right' aria-label='Mobile Toggler'></button></span><ul className=" role="menu">
	<li itemScope="itemScope" itemType="https://www.schema.org/SiteNavigationElement" id="menu-item-155" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-155 nav-item"><a title="Акты информационной безопасности" href="http://is.mindstep.ru/information-technology-security/acts/" className="dropdown-item">Акты информационной безопасности</a></li>
	<li itemScope="itemScope" itemType="https://www.schema.org/SiteNavigationElement" id="menu-item-156" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-156 nav-item"><a title="Инструкции администратора безопасности" href="http://is.mindstep.ru/information-technology-security/instructions/" className="dropdown-item">Инструкции администратора безопасности</a></li>
	<li itemScope="itemScope" itemType="https://www.schema.org/SiteNavigationElement" id="menu-item-157" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-157 nav-item"><a title="Методы защиты информации" href="http://is.mindstep.ru/information-technology-security/methods/" className="dropdown-item">Методы защиты информации</a></li>
</ul>
</li>
<li itemScope="itemScope" itemType="https://www.schema.org/SiteNavigationElement" id="menu-item-153" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-153 nav-item"><a title="Конфиденциальность" href="http://is.mindstep.ru/confidential/" className="nav-link">Конфиденциальность</a></li>
</ul>                      
							</nav>
							<div className="menu-right">
								<ul className="header-wrap-right">
									<li className="search-button">
	<button id="view-search-btn" className="header-search-toggle"><i className="fa fa-search" aria-hidden="true"></i></button>
	<div className="view-search-btn header-search-popup">
		<div className="search-overlay-layer"></div>
		<div className="header-search-pop">
			<form method="get" className="search-form" action="http://is.mindstep.ru/" aria-label="Поиск по сайту">
				<span className="screen-reader-text">Найти:</span>
				<input type="search" className="search-field header-search-field" placeholder="Введите для поиска" name="s" id="popfocus" defaultValue="" autoFocus=""/>
				<button type="submit" className="search-submit"><i className="fa fa-search" aria-hidden="true"></i></button>
			</form>
			<button type="button" className="close-style header-search-close"></button>
		</div>
	</div>
</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>


</div>
</header>
    );
  }
}
export default Header;

import React from 'react';
import { Switch, Spin, Drawer,Button,Modal, Form, Input, Select,Tooltip } from 'antd';

import { isStateMemberExpression } from 'eslint-plugin-react/lib/util/componentUtil';
import { getData,postData,getUserID,downloadFile } from './getData';
import {ConstPersonalData} from './Const_Personal_Data.js';
import operator1 from '../imgs/operator1.png';
import Header_Menu from './Header_Menu.js';
import {  CheckCircleOutlined,IssuesCloseOutlined  }  from '@ant-design/icons';
import Module_PopupBuy from './Module_PopupBuy.js';
import Module_FastDoc from './Module_FastDoc.js';

const IsLoading = (props) => {
   //console.log(props);
   return((props.loading)?<Spin tip="Загрузка данных..."/>:<div className="button_invisible"/>);
 }
 const Button_PDF = (props) => {
   
   if ((!props.buttons)||(!props.buttons.pdf)||(props.buttons.pdf.visible==false))
      return(<div className="button_invisible"/>);
   var size="default";
   return(
      <Tooltip title="Скачать примеры всех документов в формате PDF">
         <Button disabled={props.loading} className="button_green" icon={<CheckCircleOutlined />}  type="primary" shape="round"  size={size}  onClick={props.onClick}>
            Скачать PDF
         </Button> 
      </Tooltip>
   );
 }
 
 
class Main extends React.Component {
  constructor(props) {
    super(props);
    this.ClientID;
    this.PacketID;
    //this.name;
    this.state = {
      buttons:undefined,
      loading:false
      
   }
   this.getResult = this.getResult.bind(this);

   this.onClick = this.onClick.bind(this);
   this.getFileDownload = this.getFileDownload.bind(this);    
   this.onClickUved = this.onClickUved.bind(this);
   this.getFileDownload1 = this.getFileDownload1.bind(this);    

   this.onClickPDF = this.onClickPDF.bind(this);
   //this.getDownloadPDF = this.getDownloadPDF.bind(this);
   this.setStatePDFLoadingFalse = this.setStatePDFLoadingFalse.bind(this);
   this.setStatePDFLoadingTrue = this.setStatePDFLoadingTrue.bind(this);

   this.onClickWord = this.onClickWord.bind(this);
   this.getDownloadWord = this.getDownloadWord.bind(this);
   

  }
  setStatePDFLoadingFalse(){
   this.setState({ loading:false });
  }  
  setStatePDFLoadingTrue(){
   this.setState({ loading:true });
  }  
  getResult(data){
   //console.log(data);
   this.setState({ buttons: data.data });
  }
  componentDidMount() {
    if (!this.ClientID) this.ClientID=getUserID(); 
    this.PacketID=this.props.PacketID;
    getData(this.props.urlPrefix+"/makedoc/buttons?ClientID="+this.ClientID+"&PacketID="+this.props.PacketID,this.getResult);


  }
  getFileDownload(data)
  {
    console.log(data);
    
    
    if (data.status=="success")
    {
      //window.open(this.props.urlPrefix+"/makedoc/act1?file_name="+data.data.file_name, '_blank');
      downloadFile(data.data.url, "Акт определения уровня защищенности.zip");
    } else
    {
      
    }
    

  }
  /*
  onClick(e) {
    
    
    var sum_data={};
    sum_data['ClientID']=this.ClientID;
    postData(this.props.urlPrefix+"/makedoc/act1/",this.ClientID,this.getFileDownload,sum_data);

  }
  */
  getFileDownload(data)
  {
    //console.log(data);
    if (data.status=="success")
    {
      //window.open(this.props.urlPrefix+"/makedoc/act1?file_name="+data.data.file_name, '_blank');     
      downloadFile(data.data.url, "Пакет локальных нормативных актов по персональным данным.zip");
      
    } else
    {
      
    }
}
getFileDownload1(data)
{
  if (data.status=="success")
  {
    downloadFile(data.data.url, "Уведомление в Роскомнадзор.zip");
  }
}
onClick(e) {
   var sum_data={};
   sum_data['ClientID']=this.ClientID;
   //sum_data['IS_ID']='ddbb283d-683c-4e17-8796-8867cf09bb74';
   postData(this.props.urlPrefix+"/makedoc/"+this.PacketID,this.ClientID,this.getFileDownload,sum_data);
 }
 onClickUved(e) {
   var sum_data={};
   sum_data['ClientID']=this.ClientID;
   //sum_data['IS_ID']='ddbb283d-683c-4e17-8796-8867cf09bb74';
   postData(this.props.urlPrefix+"/makedoc/uved1/",this.ClientID,this.getFileDownload1,sum_data);
 }
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// PDF
/*
getDownloadPDF(data){
   //console.log(data);
    
   if (data.status=="success")
   {
   //window.open(this.props.urlPrefix+"/makedoc/act1?file_name="+data.data.file_name, '_blank');     
   //downloadFile(data.data.url, "Пакет локальных нормативных актов по персональным данным.zip",this.setStatePDFLoadingFalse,this.setStatePDFLoadingTrue);
   downloadFile(this.props.urlPrefix+"/Docs/demoPDF.zip", "Пакет локальных нормативных актов по персональным данным.zip",this.setStatePDFLoadingFalse,this.setStatePDFLoadingTrue);
   //this.setStatePDFLoadingTrue();
   } else
   {
   
   }
    
}
*/
onClickPDF(e) {
   /*
   //console.log(e);   
   var sum_data={};
   sum_data['ClientID']=this.ClientID;
   //sum_data['IS_ID']='ddbb283d-683c-4e17-8796-8867cf09bb74';
   postData(this.props.urlPrefix+"/makedoc/"+this.PacketID+'/',this.ClientID,this.getDownloadPDF,sum_data);
   */

   downloadFile(this.props.urlPrefix+"/Docs/demoPDF.zip", "Пакет локальных нормативных актов по персональным данным.zip",this.setStatePDFLoadingFalse,this.setStatePDFLoadingTrue);
   
}
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Word
getDownloadWord(data){
   //console.log(data);
    
   if (data.status=="success")
   {
   //window.open(this.props.urlPrefix+"/makedoc/act1?file_name="+data.data.file_name, '_blank');     
   downloadFile(data.data.url, this.props.name+".zip",this.setStatePDFLoadingFalse,this.setStatePDFLoadingTrue);
   //downloadFile(this.props.urlPrefix+"/Docs/demoPDF.zip", "Пакет локальных нормативных актов по персональным данным.zip",this.setStatePDFLoadingFalse,this.setStatePDFLoadingTrue);
   //this.setStatePDFLoadingTrue();
   } else
   {
   
   }
    
}

onClickWord(e) {
   //console.log(e);   
   var sum_data={};
   sum_data['ClientID']=this.ClientID;
   //sum_data['IS_ID']='ddbb283d-683c-4e17-8796-8867cf09bb74';
   postData(this.props.urlPrefix+"/makedoc/"+this.PacketID+'/',this.ClientID,this.getDownloadWord,sum_data);
   
}

 //onClickWord
 
 
  render() {
   //console.log(this.props);
   
    return (
      <div className="button_center">
      <Button_PDF
         buttons={this.state.buttons}
         onClick={this.onClickPDF}
         loading={this.state.loading}
         
         
      />
      <IsLoading loading={this.state.loading}/>        

      <Module_PopupBuy
         urlPrefix={this.props.urlPrefix}
         buttons={this.state.buttons}
      />   

      <Module_FastDoc
         urlPrefix={this.props.urlPrefix}
         buttons={this.state.buttons}
         onClick={this.onClickWord}
         loading={this.state.loading}
         name={this.props.name}
      />              


      </div>
    );
  }
};

export default Main;